import {
  ComponentProps,
  FC,
  PropsWithChildren,
  ReactNode,
  useMemo,
} from "react";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { BLOCKS, Node } from "@contentful/rich-text-types";
import classNames from "classnames";
import { ContentModuleContext } from "src/lib/context/ContentModuleContext";
import { useVerticalSpacing } from "src/lib/context/VerticalSpacingContext";

import {
  IAssemblyLink,
  ICarouselSection,
  IEmailSignupSection,
  IFaqSection,
  IFaqSectionGroup,
  IHeroSectionBasic,
  IHospitalityForm,
  IImageSection,
  IInstagramSection,
  ILinkList,
  IPackageTrackingSection,
  IProductSchematics,
  IPromoBlockSection,
  IPullQuoteSection,
  IReadMoreSection,
  IRecommendedProductsSection,
  IReviewSection,
  ISearchResultsSection,
  ISectionGroup,
  IShareForm,
  ISpacerSection,
  ITabFields,
  ITabsSection,
  IVideoSection,
} from "../../../../../@types/generated/contentful";
import { SpacerSection } from "../../../../components/DesignSystem/SpacerSection";
import { AnalyticsProvider } from "../../../hooks/useAnalytics";
import {
  isShopifyVideo,
  makeContentfulAsset,
} from "../../../util/shopifyVideo";
import ConnectedPackageTrackingSection from "../../PackageTrackingSection/PackageTrackingSection";
import { ProductCardSection } from "../../ProductCardSection";
import { ReadMoreSection } from "../../ReadMoreSection";
import { IFilter } from "../../ReviewSection/ReviewSection";
import { TabsProps } from "../../Tabs/Tabs";
import { formatMediaGallery } from "../../util";
import { makeEntryUrl } from "../CmsEditor";
import dynamicComponents from "../dynamicImports";
import {
  ErrorSectionProps,
  ProductCardType,
  RenderSectionsProps,
} from "../types";
import textMediaProps from "../util";

export { default as CardSectionSaddle } from "./CardSectionSaddle";
export { CardV2Saddle } from "./CardV2Saddle";
export { default as CenteredRichTextSaddle } from "./CenteredRichTextSaddle";
export { default as CollectionAssemblySaddle } from "./CollectionAssemblySaddle";
export { default as CollectionListSaddle } from "./CollectionListSaddle/CollectionListSaddle";
export { default as CollectionSectionSaddle } from "./CollectionSectionSaddle";
export { default as DualRichTextSectionSaddle } from "./DualRichTextSectionSaddle";
export { Grid2Dot5SectionSaddle } from "./Grid2Dot5Saddle";
export { GridSaddleWrapper } from "./GridSaddle";
export { default as Hero2Dot5Saddle } from "./Hero2Dot5Saddle";
export { default as HighlightsSectionSaddle } from "./HighlightsSectionSaddle";
export { default as PrivacySectionSaddle } from "./PrivacySectionSaddle";
export { default as ShopTheLookSectionSaddle } from "./ShopTheLookSectionSaddle";
export { default as SplitTextSectionSaddle } from "./SplitTextSectionSaddle";
export { default as TextImageSectionSaddle } from "./TextImageSectionSaddle";
export { default as TextOverlayMediaSaddle } from "./TextOverlayMediaSaddle";
export { default as TextSection2Dot5Saddle } from "./TextSection2Dot5Saddle";
const {
  AnimateVisible,
  AssemblyLink,
  CarouselSection,
  CollapsibleSection,
  CollapsibleSectionGroup,
  EmailSignupSection,
  Heading,
  HeroSection,
  HospitalityForm,
  ImageSection,
  InstagramSection,
  LinkList,
  ProductSchematics,
  PromoBlockSection,
  PullQuoteSection,
  RecommendedProductsSection,
  ReviewSection,
  RichText,
  ShareForm,
  SiteVideo,
  Tabs,
  SearchResultsSection,
} = dynamicComponents;

export function SectionError({
  title,
  children,
  entryID,
  environment,
  spaceID,
}: PropsWithChildren<ErrorSectionProps>) {
  return (
    <div className="p-4 bg-blush">
      <Heading as="h3">Error: {title}</Heading>
      <p>{children}</p>
      <a
        href={makeEntryUrl(environment, spaceID, entryID)}
        target="_blank"
        rel="noreferrer noopener"
      >
        Edit this entry
      </a>
    </div>
  );
}

export function CarouselSectionSaddle({
  content,
}: {
  content: ICarouselSection;
}) {
  const {
    desktopContent,
    enableAutoplay,
    enableNav,
    enablePagination,
    loop,
    mobileContent,
    navAlignment,
    useModernLayout,
  } = content.fields;
  return (
    <CarouselSection
      key={content?.sys?.id}
      desktopContent={desktopContent}
      enableAutoplay={enableAutoplay}
      enableNav={enableNav}
      enablePagination={enablePagination}
      loop={loop}
      mobileContent={mobileContent}
      navAlignment={navAlignment}
      useModernLayout={useModernLayout}
    />
  );
}

const makeCustomH2 = (_node: Node, children: ReactNode) => (
  <Heading as="h2" className="!text-xl lg:!text-2xl">
    {children}
  </Heading>
);

export function VideoSectionSaddle({
  content,
  environment,
  space,
}: {
  content: IVideoSection;
  environment: string;
  space: string;
}) {
  const {
    content: richTextResponse,
    video,
    videoAsset,
    embedUrl,
    autoplay,
    muted,
    loop,
  } = content.fields;

  type SiteVideoProps = ComponentProps<typeof SiteVideo>;

  let displayedVideo = video;
  let previewAsset;
  if (videoAsset && isShopifyVideo(videoAsset.fields.shopifyVideo)) {
    previewAsset = videoAsset.fields.shopifyVideo.previewImage.url;
    displayedVideo = makeContentfulAsset(videoAsset.fields.shopifyVideo);
  }

  let props: SiteVideoProps;
  if (displayedVideo) {
    props = {
      video: {
        src: displayedVideo.fields.file.url,
        type: displayedVideo.fields.file.contentType,
      },
      autoplay,
      muted,
      loop,
      previewAsset,
    };
  } else if (embedUrl) {
    props = { embedUrl };
  } else {
    return (
      <SectionError
        key={content.sys.id}
        title="Video Section missing properties"
        entryID={content.sys.id}
        environment={environment}
        spaceID={space}
      >
        A <b>Video</b> or <b>Embed URL</b> is required to render this section
        correctly.
      </SectionError>
    );
  }

  return (
    <SiteVideo
      key={content.sys.id}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {richTextResponse && (
        <RichText
          richTextResponse={richTextResponse}
          customOptions={{
            [BLOCKS.HEADING_2]: makeCustomH2,
          }}
        />
      )}
    </SiteVideo>
  );
}

export function InstagramSectionSaddle({
  content,
}: {
  content: IInstagramSection;
}) {
  const { title, caption, hashtag, handle, posts } = content.fields;
  const formattedPosts: ComponentProps<typeof InstagramSection>["posts"] =
    posts.map(
      ({
        fields: {
          url,
          image: {
            fields,
            fields: { file, description },
          },
        },
      }) => ({
        imageURL: file.url,
        height: file.details.image?.height,
        width: file.details.image?.width,
        alt: description || fields.title,
        postURL: url,
      })
    );

  return (
    <InstagramSection
      key={content.sys.id}
      title={title}
      caption={caption}
      handle={handle}
      hashtag={hashtag}
      posts={formattedPosts}
    />
  );
}

export function PullQuoteSectionSaddle({
  content,
}: {
  content: IPullQuoteSection;
}) {
  const {
    title,
    backgroundColor,
    quotes: rawQuotes,
    displayAs,
  } = content.fields;
  const quotes: ComponentProps<typeof PullQuoteSection>["quotes"] =
    rawQuotes.map(
      ({
        sys: { id: quoteID },
        fields: { quote, author, url, name, date, quoteTitle, buttonText },
      }) => ({
        quote,
        author,
        url,
        id: quoteID,
        name,
        date,
        quoteTitle,
        buttonText,
      })
    );

  return (
    <PullQuoteSection
      key={content.sys.id}
      title={title}
      quotes={quotes}
      bgColor={backgroundColor}
      displayAs={displayAs}
    />
  );
}

export function EmailSignupSectionSaddle({
  content,
}: {
  content: IEmailSignupSection;
}) {
  const {
    title,
    body,
    backgroundColor,
    backgroundImage,
    placeholderText,
    mobilePlaceholderText,
    successText,
    mobileSuccessText,
    errorText,
    mobileErrorText,
    subscriberSource,
    klaviyoSubOptions,
    overlayColorTop,
    overlayColorMiddle,
    overlayColorBottom,
    overlayOpacity,
  } = content.fields;
  const imgUrl = backgroundImage?.fields.file.url;
  return (
    <EmailSignupSection
      key={content.sys.id}
      title={title}
      backgroundColor={backgroundColor}
      backgroundImage={imgUrl}
      placeholderText={placeholderText}
      mobilePlaceholderText={mobilePlaceholderText}
      errorText={errorText}
      mobileErrorText={mobileErrorText}
      successText={successText}
      mobileSuccessText={mobileSuccessText}
      subscriberSource={subscriberSource}
      klaviyoSubOptions={klaviyoSubOptions}
      overlayColorTop={overlayColorTop}
      overlayColorMiddle={overlayColorMiddle}
      overlayColorBottom={overlayColorBottom}
      overlayOpacity={overlayOpacity}
    >
      {body && <RichText richTextResponse={body} />}
    </EmailSignupSection>
  );
}

export function RecommendedProductsSectionSaddle({
  content,
}: {
  content: IRecommendedProductsSection;
}) {
  const { name, carouselConfig, heading, rulesetId, showPricing } =
    content.fields;
  return (
    <AnalyticsProvider object={{ name, id: content.sys.id }}>
      <RecommendedProductsSection
        heading={heading}
        rulesetId={rulesetId}
        key={content.sys.id}
        max={12}
        carouselConfig={carouselConfig?.fields}
        showPricing={showPricing}
      />
    </AnalyticsProvider>
  );
}

export function ReviewSectionSaddle({ content }: { content: IReviewSection }) {
  const { product, reviewsShown, title, type, reviewIds, url, filters } =
    content.fields;
  return (
    <ReviewSection
      key={content.sys.id}
      product={product}
      reviewsShown={reviewsShown}
      title={title}
      type={type}
      url={url}
      reviewIds={reviewIds}
      filters={filters as IFilter[] | undefined}
    />
  );
}

export function ProductSchematicsSaddle({
  content,
}: {
  content: IProductSchematics;
}) {
  const {
    matchOption,
    materialsDescription,
    optionSelector,
    schematics: schematicsData,
    mediaGallery,
  } = content.fields;

  const media = formatMediaGallery(mediaGallery);
  const schematics = schematicsData.map(({ fields, sys }) => {
    const {
      file: { details, url },
      description,
      title,
    } = fields.image.fields;
    return {
      description: fields.description && (
        <RichText richTextResponse={fields.description} />
      ),
      id: sys.id,
      image: {
        src: url,
        height: details.image?.height,
        width: details.image?.width,
        alt: description || title,
        description,
        title,
      },
      title: fields.title,
      media: formatMediaGallery(fields.mediaGallery),
      visibility: {
        optionName: fields.visibilityOptionName,
        comparisonOperator: fields.visibilityComparisonOperator,
        optionValue: fields.visibilityOptionValue,
      },
    };
  });

  return (
    <ProductSchematics
      key={content.sys.id}
      matchOption={matchOption}
      optionSelector={
        optionSelector &&
        (optionSelector as ComponentProps<
          typeof ProductSchematics
        >["optionSelector"])
      }
      media={media}
      schematics={schematics}
      defaultDescription={
        materialsDescription ? (
          <RichText richTextResponse={materialsDescription} />
        ) : undefined
      }
    />
  );
}

export function TabSectionsSaddle({
  content,
  RenderSectionComponent,
}: {
  content: ITabsSection;
  RenderSectionComponent: FC<RenderSectionsProps>;
}) {
  const tabs: TabsProps["tabs"] = content.fields.tabs.map(
    ({
      sys: { id: tabId },
      fields,
      fields: { title, icon, content: tabContent },
    }) => {
      const base = {
        id: tabId,
        title,
        icon: icon
          ? {
              src: icon.fields.file.url,
              height: icon.fields.file.details.image?.height,
              width: icon.fields.file.details.image?.width,
            }
          : undefined,
      };

      if (!("fields" in tabContent)) {
        const { sideContent, mediaGallery: media } = fields as ITabFields;
        const mediaGallery = formatMediaGallery(media);
        return {
          ...base,
          content: <RichText richTextResponse={tabContent} />,
          sideContent: sideContent ? (
            <RichText richTextResponse={sideContent} />
          ) : undefined,
          mediaGallery,
        };
      }

      return {
        ...base,
        embed: <RenderSectionComponent sections={[tabContent]} />,
      };
    }
  );
  return <Tabs key={content.sys.id} tabs={tabs} />;
}

export function FaqSectionGroupSaddle({
  content,
  id,
}: {
  content: IFaqSectionGroup;
  id: string;
}) {
  const { collapsibleSections, name, hideSectionTitles } = content.fields;
  return (
    <CollapsibleSectionGroup
      key={id}
      collapsibleSections={collapsibleSections}
      name={name}
      hideSectionTitles={Boolean(hideSectionTitles)}
    />
  );
}

export function FaqSectionSaddle({
  content,
  id,
}: {
  content: IFaqSection;
  id: string;
}) {
  const { hasVerticalSpacing } = useVerticalSpacing();
  const {
    id: sectionId,
    name,
    adBanner,
    collapsibles,
    shouldCollapse,
    layout = "legacy",
    limit,
    title,
    showBackToTop,
  } = content.fields;

  return (
    <div
      className={classNames("w-full m-auto ", {
        "max-w-4xl px-5 md:px-0": layout === "legacy",
        "max-w-screen-king px-6 full:px-8 ": layout === "modern",
        "py-6 full:py-14": hasVerticalSpacing && layout === "modern",
      })}
    >
      <CollapsibleSection
        key={id}
        limit={limit}
        layout={layout}
        showBackToTop={showBackToTop}
        id={sectionId}
        name={name}
        title={title}
        shouldCollapse={shouldCollapse}
        adBanner={adBanner ? textMediaProps(adBanner) : null}
        collapsibles={collapsibles.map((i) => ({
          id: i.sys.id,
          title: i.fields.title,
          copy: <RichText richTextResponse={i.fields.copy} />,
          anchor: i.fields.id,
          seoData: {
            question: i.fields.title,
            answer: documentToPlainTextString(i.fields.copy),
          },
        }))}
      />
    </div>
  );
}

export function ImageSectionSaddle({ content }: { content: IImageSection }) {
  const { images } = content.fields;
  return (
    <AnimateVisible fadeIn animateUp key={content.sys.id}>
      <ImageSection
        images={images.map(
          ({
            fields: {
              description,
              title,
              file: {
                url: src,
                details: { image },
              },
            },
          }) => {
            const alt = description || title;
            return {
              src,
              alt,
              height: image?.height,
              width: image?.width,
            };
          }
        )}
      />
    </AnimateVisible>
  );
}

export function PromoBlockSectionSaddle({
  content,
}: {
  content: IPromoBlockSection;
}) {
  const {
    fields: { blocks },
  } = content as IPromoBlockSection;
  return (
    <PromoBlockSection
      key={content.sys.id}
      blocks={blocks.map(({ fields: block, sys: { id: blockId } }) => ({
        id: blockId,
        title: block.title,
        content: block.content,
        background: block.backgroundColor,
        icon: {
          src: block.icon.fields.file.url,
          height: block.icon.fields.file.details.image?.height,
          width: block.icon.fields.file.details.image?.width,
        },
      }))}
    />
  );
}

export function HospitalityFormSaddle({
  content,
}: {
  content: IHospitalityForm;
}) {
  const {
    fields: { sectionId },
  } = content;
  return <HospitalityForm id={sectionId} key={content.sys.id} />;
}

export function ShareFormSectionSaddle({ content }: { content: IShareForm }) {
  const {
    fields: {
      title,
      description,
      giftText,
      receiveText,
      placeholder1,
      placeholder2,
      ctaText,
    },
  } = content;
  return (
    <ShareForm
      title={title}
      description={description}
      giftText={giftText}
      receiveText={receiveText}
      placeholder1={placeholder1}
      placeholder2={placeholder2}
      ctaText={ctaText}
      key={content.sys.id}
    />
  );
}

export function LinkListSectionSaddle({ content }: { content: ILinkList }) {
  const { items } = content.fields;
  return <LinkList key={content.sys.id} items={items} />;
}

export function AssemblyLinkSectionSaddle({
  content,
}: {
  content: IAssemblyLink;
}) {
  const { title, url } = content.fields;
  return <AssemblyLink key={content.sys.id} title={title} url={url} />;
}

export function HeroSectionSaddle({
  content,
  index,
}: {
  content: IHeroSectionBasic;
  index: number;
}) {
  const { textOverlayMedia, textOverlayMediaList, autoPlay, name } =
    content.fields;
  const tom =
    textOverlayMediaList || (textOverlayMedia ? [textOverlayMedia] : []);

  const moduleContextValue = useMemo(
    () => ({
      modulePosition: index + 1,
      moduleEntryName: name ?? "",
      moduleEntryId: content.sys.id,
      moduleType: content.sys.contentType.sys.id,
    }),
    [index, name, content]
  );

  return (
    <ContentModuleContext.Provider value={moduleContextValue}>
      <HeroSection
        textOverlayMediaList={tom.map(textMediaProps)}
        autoPlay={autoPlay}
      />
    </ContentModuleContext.Provider>
  );
}

export function SpacerSectionSaddle({ content }: { content: ISpacerSection }) {
  const props = content.fields;
  return <SpacerSection size={props.size} />;
}

export function PackageTrackingSectionSaddle({
  content,
}: {
  content: IPackageTrackingSection;
}) {
  const props = content.fields;
  return (
    <div className="flex justify-center">
      <ConnectedPackageTrackingSection
        headerInitial={props.initialTopSection}
        headerSuccess={props.shipmentDetailsTopSection}
      />
    </div>
  );
}

export function SearchResultsSectionSaddle({
  content,
}: {
  content: ISearchResultsSection;
}) {
  const props = content.fields;
  return <SearchResultsSection showSearchBar={props.showSearchBar} />;
}

export function ReadMoreSectionSaddle({
  content,
}: {
  content: IReadMoreSection;
}) {
  const {
    title,
    content: document,
    collapseButtonText,
    expandButtonText,
    defaultExpanded,
  } = content.fields;
  return (
    <ReadMoreSection
      key={content.sys.id}
      title={title}
      collapseButtonText={collapseButtonText}
      expandButtonText={expandButtonText}
      defaultOpen={defaultExpanded}
    >
      <RichText richTextResponse={document} />
    </ReadMoreSection>
  );
}

export function SectionGroupSaddle({
  content,
  RenderSectionsComponent,
  index,
}: {
  content: ISectionGroup;
  RenderSectionsComponent: FC<RenderSectionsProps>;
  index: number;
}) {
  const {
    fields: { sections, name },
    sys: {
      id: moduleEntryId,
      contentType: {
        sys: { id: moduleType },
      },
    },
  } = content;
  const moduleContextValue = useMemo(
    () => ({
      modulePosition: index + 1,
      moduleEntryName: name,
      moduleEntryId,
      moduleType,
    }),
    [index, name, moduleEntryId, moduleType]
  );

  return (
    <ContentModuleContext.Provider value={moduleContextValue}>
      <RenderSectionsComponent sections={sections} />
    </ContentModuleContext.Provider>
  );
}

export function ProductCardSaddle({ content }: { content: ProductCardType }) {
  const {
    fields,
    sys: {
      id,
      contentType: {
        sys: { id: type },
      },
    },
  } = content;
  return (
    <AnalyticsProvider object={{ id, name: fields.name, type }}>
      <ProductCardSection product={fields} />
    </AnalyticsProvider>
  );
}
