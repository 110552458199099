/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Card, PromoBadge } from "@components";
import { useRouter } from "next/router";
import { useRunOnVisibleOnce } from "src/hooks/useIntersectionObserver";
import ProductTileContext from "src/lib/context/ProductTileContext";
import { useBreakpoints } from "src/lib/hooks/useBreakpoints";
import { checkFeaturedImageAspectRatio } from "src/lib/services/catalog/utils";
import {
  addHeapProductTileClicked,
  addHeapProductTileSwatchClicked,
  addHeapProductTileViewed,
} from "src/lib/services/elevar/events";

import type { CardProps } from "../../../../../components/Card/Card";
import { PromoBadgePreset } from "../../../../../components/PromoBadge";
import useCatalog from "../../../../hooks/useCatalog";
import type { FormattedCollectionItem } from "../../../../queries/data/collectionData";
import type { CatalogProduct } from "../../../../services/catalog/types";
import { getShopifyId } from "../../../../services/util/shopifyID";
import { isTruthy } from "../../../../util";
import makeProductUrl from "../../../../util/makeProductUrl";
import { isOptionConfigMatching } from "../../../../util/optionMatch";

import selectors from "./selectors";
import Toolbar from "./Toolbar";

type ProductVariant = CatalogProduct["variants"][number];

type Props = CardProps &
  Pick<FormattedCollectionItem, "optionName" | "optionValues" | "id"> & {
    variantId: string;
    contentfulName: string;
    badgePreset: PromoBadgePreset | null;
    contentfulId?: string;
    isFeatured?: boolean;
  };

const MAX_DESKTOP_OPTION_VALUES = 4;

export default function ProductCard({
  optionName,
  optionValues,
  badgePreset,
  id: rawProductId,
  variantId: rawVariantId,
  isFeatured = false,
  ...props
}: Props) {
  const initialVariantId = getShopifyId(rawVariantId);
  const productId = getShopifyId(rawProductId);
  const { catalog } = useCatalog([productId]);
  const tileContext = useContext(ProductTileContext);
  const position = tileContext?.tilePosition;
  const productListSectionTitle = tileContext?.productListSectionTitle;

  const { pathname } = useRouter();
  const isCollectionPage = pathname.includes("/collections/");

  const [currentVariant, setCurrentVariant] = useState<ProductVariant>();

  const product = useMemo(() => {
    if (!catalog) return null;

    const catalogProduct = catalog[productId];

    if (!catalogProduct) {
      // eslint-disable-next-line no-console
      console.error(
        `Product with id "${rawProductId}" was not found in the catalog`
      );
      return null;
    }

    return catalogProduct;
  }, [catalog, productId, rawProductId]);

  useEffect(() => {
    const matchingVariant = product?.variants[initialVariantId];
    setCurrentVariant(matchingVariant);
  }, [product, initialVariantId]);

  const { option, truncatedItemCount = 0 } = useMemo(() => {
    if (!optionName || !product) return { option: null };

    const matchingOption = product.options.find((o) => o.name === optionName);
    if (!matchingOption) {
      // eslint-disable-next-line no-console
      console.error(
        `Could not find matching option with name "${optionName}" in ${product.productTitle}`
      );
      return { option: null };
    }

    const sourceValues = optionValues?.length
      ? optionValues
          .map((value) =>
            matchingOption.values.find(
              (optionValue) => optionValue.value === value
            )
          )
          .filter(isTruthy)
      : matchingOption.values;
    const values = sourceValues.slice(0, MAX_DESKTOP_OPTION_VALUES);

    if (!values.length) {
      // eslint-disable-next-line no-console
      console.error(
        `No resulting option values for "${optionName}" in ${product.productTitle}`
      );
      return {
        option: null,
      };
    }

    return {
      option: {
        ...matchingOption,
        values,
      },
      truncatedItemCount: Math.max(
        matchingOption.values.length - values.length,
        0
      ),
    };
  }, [product, optionName, optionValues]);

  const { fullVisible } = useBreakpoints();

  const { currentImageUrl, currentImageWidth, currentImageHeight } =
    useMemo(() => {
      const defaultImage = props.image.src;
      if (!currentVariant)
        return {
          currentImageUrl: defaultImage,
          currentImageWidth: props.image.width,
          currentImageHeight: props.image.height,
        };

      const variantImage =
        isFeatured && fullVisible
          ? currentVariant.cardImageFeatured
          : currentVariant.cardImage;
      if (!variantImage?.url)
        return {
          currentImageUrl: defaultImage,
          currentImageWidth: props.image.width,
          currentImageHeight: props.image.height,
        };
      return {
        currentImageUrl: variantImage.url,
        currentImageWidth: variantImage.width,
        currentImageHeight: variantImage.height,
      };
    }, [
      props.image.src,
      props.image.width,
      props.image.height,
      currentVariant,
      isFeatured,
      fullVisible,
    ]);

  const hoverImageUrl = useMemo(
    () =>
      (isFeatured && fullVisible
        ? currentVariant?.cardImageFeaturedHover?.url
        : currentVariant?.cardImageHover?.url) ?? "",
    [currentVariant, isFeatured, fullVisible]
  );

  const imageProps = {
    ...props.image,
    src: isFeatured ? currentImageUrl : `${currentImageUrl}?w=500`,
    width: currentImageWidth,
    height: currentImageHeight,
  };
  const hoverImageProps =
    props.hoverImage && hoverImageUrl
      ? {
          ...props.hoverImage,
          width: currentImageWidth,
          height: currentImageHeight,
          src: isFeatured ? hoverImageUrl : `${hoverImageUrl}?w=500`,
        }
      : undefined;

  const partialProductTileData = {
    productID: productId,
    variantID: rawVariantId,
    productListSectionTitle,
    defaultVariantID: product?.defaultVariant,
    sku: currentVariant?.sku,
    productTileTitle: props.contentfulName,
    productColorSelected: currentVariant?.selectedOptions.find(
      (o) => o.name === "Color"
    )?.value,
    visibleOnPageload: tileContext?.visibleOnPageload,
    defaultImageURL: props.image.src,
    displayedImageURL: currentImageUrl,
    location: isCollectionPage
      ? ("Collection List - PLP" as const)
      : ("Collection List - Other" as const),
  };

  const cardRef = useRef<HTMLDivElement>(null);
  const getListPosition = () => {
    const productLists = document.querySelectorAll(
      "[data-collection-list-index]"
    );
    const parentCollectionList = cardRef.current?.closest(
      "[data-collection-list-index]"
    );
    const parentCollectionListIndex = parentCollectionList
      ? parentCollectionList.getAttribute("data-collection-list-index")
      : null;
    const productListIndex = Array.from(productLists)
      .sort()
      .findIndex(
        (list) =>
          list.getAttribute("data-collection-list-index") ===
          parentCollectionListIndex
      );
    return { productListPosition: productListIndex + 1 || undefined };
  };

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    props.onClick?.(e);
    if (currentVariant && product) {
      addHeapProductTileClicked({
        variantTitle: currentVariant.title,
        price: currentVariant.price,
        productTitle: product.productTitle,
        productTileSectionPosition: position,
        ...getListPosition(),
        ...partialProductTileData,
      });
    }
  };

  const handleCardViewed = () => {
    if (currentVariant && product) {
      addHeapProductTileViewed({
        variantTitle: currentVariant.title,
        price: currentVariant.price,
        productTitle: product.productTitle,
        productTileSectionPosition: position,
        ...getListPosition(),
        ...partialProductTileData,
      });
    }
  };

  // Only attach to the card with product data, doesn't make sense to track without it
  const divRef = useRunOnVisibleOnce(handleCardViewed, { threshold: 0.8 }, 500);

  const activeValue = currentVariant?.selectedOptions.find(
    (o) => o.name === optionName
  )?.value;

  const currentHref =
    product && currentVariant
      ? makeProductUrl(product.slug, currentVariant.id)
      : props.href;

  const selectedOptions = Object.fromEntries(
    (currentVariant?.selectedOptions ?? []).map(({ name, value }) => [
      name,
      value,
    ])
  );

  if (isFeatured && product && currentVariant) {
    const selectableOptions = {
      ...selectedOptions,
      ...(option
        ? { [option.name]: option.values.map(({ value }) => value) }
        : undefined),
    };

    const matchingVariants = Object.values(product.variants).filter((variant) =>
      Object.entries(selectableOptions).every(([optName, optVal]) =>
        variant.selectedOptions.some(
          (selectedOption) =>
            selectedOption.name === optName &&
            optVal.includes(selectedOption.value)
        )
      )
    );

    const missingFeaturedImageVariants = matchingVariants.filter(
      (variant) => !variant.cardImageFeatured?.url
    );

    if (missingFeaturedImageVariants.length) {
      throw new Error(
        `Not all matching variants have a featured image for https://app.contentful.com/spaces/t15gr55mpxw1/entries/${
          props.contentfulId
        }: ${missingFeaturedImageVariants
          .map((v) => `(id: ${v.id}, name: ${v.title})`)
          .join(", ")}`
      );
    }

    // Check to ensure all featured images have 2:1 aspect ratio
    checkFeaturedImageAspectRatio(matchingVariants, props.contentfulId);
  }

  const handleOptionClick = (value: string, index: number) => {
    // We can't get into this function without any of the following
    // but there isn't a way to make them falsy and run the event handler
    // istanbul ignore next
    if (!product || !optionName || !option || !currentVariant) return;

    const selectedOptionsMap = new Map(Object.entries(selectedOptions));

    // Update the value for the clicked option
    selectedOptionsMap.set(optionName, value);

    // Find the variant that matches the updated selected options
    const newVariant = Object.values(product.variants).find((variant) =>
      Array.from(selectedOptionsMap).every(
        ([selectedOptionName, selectedOptionValue]) =>
          variant.selectedOptions.some(
            (o) =>
              o.name === selectedOptionName && o.value === selectedOptionValue
          )
      )
    );

    setCurrentVariant(newVariant);

    // Same issue as above
    // istanbul ignore next
    if (!newVariant) return;

    const defaultSwatchValue = product.variants[
      rawVariantId
    ]?.selectedOptions.find((o) => o.name === optionName)?.value;

    addHeapProductTileSwatchClicked({
      swatchPosition: index + 1,
      swatchName: value,
      swatchSku: newVariant.sku,
      swatchVariantId: newVariant.id,
      defaultSwatchName: product.variants[
        product.defaultVariant
      ].selectedOptions.find((o) => o.name === optionName)?.value,
      defaultSwatchSku: product.variants[product.defaultVariant].sku,
      defaultSwatchVariantId: product.defaultVariant,
      defaultSwatchPosition:
        option.values.findIndex((v) => v.value === defaultSwatchValue) + 1,
    });
  };

  const sharedToolbarProps = option
    ? {
        option,
        truncatedItems: truncatedItemCount,
        activeValue,
        onOptionClick: handleOptionClick,
      }
    : null;

  const activeBadge = product?.badges?.find((badge) =>
    isOptionConfigMatching(badge.optionMatchConfigs, selectedOptions)
  );

  return (
    <div
      data-testid={
        sharedToolbarProps ? selectors.interactiveCard : selectors.staticCard
      }
      ref={divRef}
    >
      <Card
        {...props}
        image={imageProps}
        hoverImage={hoverImageProps}
        href={currentHref}
        isFeatured={isFeatured}
        onClick={handleCardClick}
        forwardedRef={cardRef}
      >
        {activeBadge && (
          <PromoBadge
            text={activeBadge.text}
            preset={badgePreset || activeBadge.preset}
            className="absolute top-4 left-4"
          />
        )}
      </Card>
      {sharedToolbarProps && <Toolbar {...sharedToolbarProps} />}
    </div>
  );
}
