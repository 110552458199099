import { ReactNode } from "react";
import { Button, Heading } from "@components";
import classNames from "classnames";
import { FeaturedCardProvider } from "src/components/Card";
import useVisibility from "src/hooks/useVisibility";
import { Media } from "src/lib/components";

import selectors from "./selectors";
import useDescriptionToggle from "./useDescriptionToggle";

interface DynamicGridItemProps {
  id: string;
  featured: boolean;
  inGridContent?: boolean;
  children: ReactNode;
}

interface DynamicGridProps {
  title: string;
  description: string;
  items: DynamicGridItemProps[];
  desktopItems?: DynamicGridItemProps[];
  toggleButtonTextCollapsed: string;
  toggleButtonTextExpanded: string;
}

function DynamicGridItem({
  id,
  featured,
  inGridContent,
  children,
}: DynamicGridItemProps) {
  return (
    <div
      data-item-id={id}
      data-testid={selectors.item}
      className={classNames({
        "col-span-2": featured,
        "col-span-2 full:col-span-1": inGridContent,
      })}
    >
      {children}
    </div>
  );
}

/**
 * Renders a grid of whatever elements are passed to it
 */
export function DynamicGrid({
  title,
  description,
  items: mobileItems,
  desktopItems = mobileItems,
  toggleButtonTextCollapsed,
  toggleButtonTextExpanded,
}: DynamicGridProps) {
  const { descriptionRef, isToggleButtonVisible } =
    useDescriptionToggle(description);
  const { isGridDescriptionVisible, toggleGridDescription } = useVisibility(
    "GridDescription",
    false
  );
  const gridClassNames = classNames(
    "grid-cols-2 gap-x-3 gap-y-4 full:gap-x-4 full:grid-cols-4"
  );

  const buttonText = isGridDescriptionVisible
    ? toggleButtonTextExpanded
    : toggleButtonTextCollapsed;

  return (
    <div data-testid={selectors.container}>
      <div className="px-6 mx-auto twinXl:max-w-screen-king full:px-8">
        <div className="flex flex-col items-baseline justify-between gap-2 py-6 full:flex-row full:py-8 border-t-[0.5px] border-t-slate/50">
          <Heading
            as="h3"
            variant="h5"
            className="font-serif text-l full:text-[25px] leading-eighth"
          >
            {title}
          </Heading>

          <div className="flex flex-col items-start gap-2">
            <p
              className={classNames(
                "leading-small full:clamp-none full:max-w-md",
                {
                  "clamp clamp-2":
                    !isGridDescriptionVisible && isToggleButtonVisible,
                }
              )}
              data-expanded={isGridDescriptionVisible}
              data-testid={selectors.description}
              ref={descriptionRef}
            >
              {description}
            </p>

            <Button
              className={classNames("hover:text-charcoal full:hidden", {
                hidden: !isToggleButtonVisible,
              })}
              onClick={toggleGridDescription}
              variant="underline"
              data-testid={selectors.descriptionToggle}
            >
              {buttonText}
            </Button>
          </div>
        </div>

        <div className="pb-6 full:pb-16">
          <Media
            lessThan="full"
            displayStyle="grid"
            className={gridClassNames}
            data-testid={selectors.mobileItemsContainer}
          >
            {mobileItems.map((item) => (
              <DynamicGridItem key={item.id} {...item} />
            ))}
          </Media>

          <Media
            greaterThanOrEqual="full"
            displayStyle="grid"
            className={gridClassNames}
            data-testid={selectors.desktopItemsContainer}
          >
            <FeaturedCardProvider>
              {desktopItems.map((item) => (
                <DynamicGridItem key={item.id} {...item} />
              ))}
            </FeaturedCardProvider>
          </Media>
        </div>
      </div>
    </div>
  );
}
