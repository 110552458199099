import { PropsWithChildren } from "react";
import { cn } from "src/utils/tailwind-merge";

type MediaQuery<T> = {
  base: T;
  desktop: T;
};

type TagOptions = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /** The native html tag to use for the heading */
  as: TagOptions;
  /** The variant style to use for the heading */
  variant?: TagOptions | MediaQuery<TagOptions>;
  /** Additional classnames */
  className?: string;
}

export function Heading({
  as,
  variant = as,
  children,
  className,
  ...rest
}: PropsWithChildren<HeadingProps>) {
  const ResolvedTag = as;
  return (
    <ResolvedTag
      className={cn(
        `font-normal`,
        {
          "leading-h1 text-h1 tracking-h1 font-serif":
            variant === "h1" ||
            (typeof variant !== "string" && variant.base === "h1"),

          "leading-h2 text-h2 tracking-h2 font-serif":
            variant === "h2" ||
            (typeof variant !== "string" && variant.base === "h2"),

          "leading-h3 text-h3 tracking-h3 font-serif":
            variant === "h3" ||
            (typeof variant !== "string" && variant.base === "h3"),

          "leading-h4 text-h4 tracking-h4 font-serif":
            variant === "h4" ||
            (typeof variant !== "string" && variant.base === "h4"),

          "leading-h5 text-h5 tracking-h5 font-sans":
            variant === "h5" ||
            (typeof variant !== "string" && variant.base === "h5"),

          "leading-h6 text-h6 tracking-h6 font-sans":
            variant === "h6" ||
            (typeof variant !== "string" && variant.base === "h6"),

          "full:leading-h1 full:text-h1 full:tracking-h1 full:font-serif":
            typeof variant !== "string" && variant.desktop === "h1",

          "full:leading-h2 full:text-h2 full:tracking-h2 full:font-serif":
            variant === "h2" ||
            (typeof variant !== "string" && variant.desktop === "h2"),

          "full:leading-h3 full:text-h3 full:tracking-h3 full:font-serif":
            variant === "h3" ||
            (typeof variant !== "string" && variant.desktop === "h3"),

          "full:leading-h4 full:text-h4 full:tracking-h4 full:font-serif":
            variant === "h4" ||
            (typeof variant !== "string" && variant.desktop === "h4"),

          "full:leading-h5 full:text-h5 full:tracking-h5 full:font-sans":
            variant === "h5" ||
            (typeof variant !== "string" && variant.desktop === "h5"),

          "full:leading-h6 full:text-h6 full:tracking-h6 full:font-sans":
            variant === "h6" ||
            (typeof variant !== "string" && variant.desktop === "h6"),
        },
        className
      )}
      {...rest}
    >
      {children}
    </ResolvedTag>
  );
}
