import type { GenericObjectInfo } from "../../hooks/useAnalytics";
import type { RecommendedProduct } from "../../hooks/useRecommendedProductsQuery";
import type {
  AddToCartPayload,
  RemoveFromCartPayload,
  UpdateCartPayload,
} from "../../queries/data/cartData";
import { FormattedCollectionItem } from "../../queries/data/collectionData";
import { MergedProduct } from "../../queries/data/productData";
import { defaultLocale, getLocale, LocaleCode } from "../../util/locale";
import makeProductUrl from "../../util/makeProductUrl";
import type { LineItem } from "../cart/types";
import type { MergedProductVariant } from "../catalog/types";
import { getShopifyId } from "../util/shopifyID";

import eventManager from "./eventManager";
import {
  IAddToCart,
  IBaseLayer,
  IBISSignupData,
  IContentModuleProps,
  IEmailSignupData,
  IEvent,
  IImpression,
  IInitCart,
  IItemSelect,
  IPartialProduct,
  IProduct,
  IProductTileProps,
  IRemoveFromCart,
  IRouteChange,
  ISearchResultsItemSelect,
  ISignupSelect,
  ISubmitB2BForm,
  IUserProperties,
  IViewCart,
  IViewCollection,
  IViewMarketingCapture,
  IViewProduct,
  IViewSearchPage,
} from "./interfaces";

/*
 * 
  <!--- Elevar Base Data Layer ----!>
  // dl_user_data should fire on every page load
	// This event MUST precede all other data layer events
  window.dataLayer.push({
    "event": "dl_user_data",
    "device": device, // See device object below
    "page": { title: "T-Shirt Collection" }, // page title
		"cart_total": "{{ cart.total_price }}",
    "user_properties": user_properties, // See user properties object below
		"marketing": marketing, // See the marketing object
		"ecommerce": {
			"cart_contents": {
				"products": products // See the products array below
			},
			"currencyCode": "USD",
		}
  });

	<!--- Customer creates new account  ----!>
  // If your users don't typically sign up this event is not required
  window.dataLayer.push({
    "event": "dl_sign_up"
    "page": { title: "T-Shirt Collection" }, // page title
    "user_properties": user_properties, // See user properties object below
		"marketing": marketing, // See the marketing object below
  });

<!--- Customer Logs into their account ----!>
  // If your users don't typically login this event is not required
  window.dataLayer.push({
    "event": "dl_login",
    "page": { title: "T-Shirt Collection" }, // page title
    "user_properties": user_properties, // See user properties object below
		"marketing": marketing // See the marketing object below
  });


<!--- Collection page product impressions ----!>
  window.dataLayer.push({
  "event": "dl_view_item_list",
  "user_properties": user_properties,
	"marketing": marketing, // See the marketing object below
	  "ecommerce": {
	    "currencyCode": "{{ shop.currency }}",
      "impressions": impressions, // See impressions array below
	  }
  });

<!--- Search page product impressions  ----!>
  window.dataLayer.push({
    "event": "dl_view_search_results",
		"user_properties": user_properties,
    "marketing": marketing, // See the marketing object below
    "ecommerce": {
      "currencyCode": "{{ shop.currency }}",
      "actionField": { "list": 'search results' },  
      "impressions": impressions, // See impressions array below
    }
  });

<!--- Collection/Search page product click...this is the product the user clicks on from collection page ----!>
  window.dataLayer.push({
    "event": "dl_select_item",
		"user_properties": user_properties,
    "marketing": marketing, // See the marketing object below
    "ecommerce": {
      "currencyCode": "{{ shop.currency }}",
      "click": {
        "actionField": {'list': 'location.pathname', 'action': 'click'},      // this should be the collection page URL
        "products": products // See the products array below
      }
    },
  });


<!--- Product detail view // note the list object which carries from collection page
When user selects variant this will push an additional event with revised product data
----!>
  window.dataLayer.push({
    "event": "dl_view_item",
		"user_properties": user_properties,
		"marketing": marketing, // See the marketing object below
    "ecommerce": {
      "currencyCode": "{{ shop.currency }}",
      "detail": {
       "actionField": {'list': 'location.pathname', action: "detail"},      // this should be the collection page URL that user clicked product from
       "products": products // See the products array below
      }
    }
  });



<!--- Add to Cart // note the list object which carries from collection page ----!>
  window.dataLayer.push({
    "event": "dl_add_to_cart",
    "user_properties": user_properties,
		"marketing": marketing, // See the marketing object below
    "ecommerce": {
      "currencyCode": "{{ shop.currency }}",
      "add": {
       "actionField": {'list': 'location.pathname'}, // this should be the collection page URL that user clicked product from
       "products": products // See the products array below
      }
    }
  });



<!--- Remove from Cart // note the list object which carries from collection page ----!>
  window.dataLayer.push({
    "event": "dl_remove_from_cart",
    "user_properties": user_properties,
		"marketing": marketing, // See the marketing object below
    "ecommerce": {
      "currencyCode": "{{ shop.currency }}",
      "remove": {
       "actionField": {'list': 'location.pathname', 'action': 'add'},      // this should be the collection page URL that user clicked product from
       "products": products // See the products array below
      }
    }
  });


<!--- View Cart/Mini Cart ----!>
  window.dataLayer.push({
    "event": "dl_view_cart",
    "user_properties": user_properties,
    "cart_total": "{{ cart.total_price | money_without_currency | remove:',' }}",
		"marketing": marketing, // See the marketing object below
    "ecommerce": {
      "currencyCode": "{{ shop.currency }}",
      "actionField": { "list": "Shopping Cart" },
      "impressions": impressions, // See impressions array below
    }
  });


<!--- Begin Checkout - NON PLUS STORES ONLY ----!>
<!--- Fire this event right before transitioning to checkout ----!>
window.dataLayer.push({
		"event": "dl_begin_checkout",
		"user_properties": user_properties,
		"marketing": marketing, // See the marketing object below
    "ecommerce": {
      "currencyCode": "USD",
      "checkout": {
          "actionField": {
              "step": "1",
              "action": "checkout",
          },
          "products": products, // See products array below
      },
    }
});

<!--- Route Change ----!>
// This should be pushed to the data layer on any route changes
// Clients using Gatsby typically use the gatsby-plugin-google-tagmanager
// This plugin fires gatsby-route-change event in GTM.
// You can change the name of this event to dl_route_change in the config
// This event helps us interpret page views.
window.dataLayer.push({ "event" : dl_route_change })



<!--- Products Array ----!>
products = [
	{
		id: "LB00161-34689553170476", // SKU
    name: "Lovebox Original Color & Photo", // Product title
    brand: "Lovebox INC",
    category: "Home,Living,Art & Objects,Tabletop",
    variant: "USA wall plug",
    price: "119.99",
    quantity: "1",
    list: "/art/wallhangings", // The list the product was discovered from or is displayed in
    product_id: "6979886940352", // The product_id
    variant_id: "41141193965760", // id or variant_id
    compare_at_price: "139.99", // If available on dl_view_item & dl_add_to_cart otherwise use an empty string
    image: "//cdn.shopify.com/small.png", // If available, otherwise use an empty string
		inventory: "5", // If available, only required on dl_view_item
	}...
]

<!--- Impressions Array ----!>
impressions = [
	{
		id: "LB00161-34689553170476", // SKU
    name: "Lovebox Original Color & Photo", // Product title
    brand: "Lovebox INC",
    category: "Home,Living,Art & Objects,Tabletop",
    variant: "USA wall plug",
    price: "119.99",
		quantity: "1", // Only required for dl_view_cart
    list: "/art/wallhangings", // The list the product was discovered from or is displayed in
    product_id: "6979886940352", // The product_id
    variant_id: "41141193965760", // id or variant_id
		compare_at_price: "139.99", // If available 
		image: "//cdn.shopify.com/small.png", // If available, otherwise use an empty string
		position: item.position, // position in the list of search results, collection views and position in cart indexed starting at 1
	}...
]

<!--- Marketing Object ----!>
// Determine which channels are currently being used, or will be used on the new site.
// Look for these cookie values if available and attach to the marketing object.
// If unavailable do not send empty strings
// Ensure to also send the user_id value which is your unique user id persisted across sessions
marketing = {
		// This is the GA4 cookie ID. The XXX... portion of the cookie will differ for every client
		"_ga_XXXXXXXXXX": "GS1.1.1649714540.17.0.1.60", // GA4 Cookie ID
    "_fbp": "fb.1.1649615616201.121355119", // FB cookie id
	  "_fbc": ,// FB cookie id if available
    "_ga": "GA1.2.1173945483.1649615616", // GA cookie id
	  "_gaexp": ,// Optimize cookie id if available
		"_gid": "GA1.2.854795203.1651098012",// GA cookie id if available
		"__utma": ,// GA cookie id if available
	  "ttclid": ,// TikTok cookie ID if using TikTok
		"crto_mapped_user_id": "dlkjla38uj", // Criteo cookie id if using Criteo
		"crto_is_user_optout": "false" // Criteo opt out status
		"user_id": "bc574dca-c842-4de7-98a1-dd9529729456", // UUID uniqe per user, should be persisted as long as possible and kept consistent between sessions
}

<!--- Device Object ----!>
// Pull appropriate values from the client. Values below are examples.
device = {
    "screen_resolution": "3008x1692", 
    "viewport_size": "1382x3711",
    "encoding": "UTF-8",
    "language": "en-US",
    "colors": "24-bit"
}

<!--- User Properties Object ----!>
// The majority of this information can only be retrieved for a logged in user
user_properties = {
	// The following fields aren't required if unavailable
  customer_address_1: "1 Hills Plantation Drive"
	customer_address_2: ""
	customer_city: "Charleston"
	customer_country: "United States"
	customer_email: "bill@gmail.com"
	customer_first_name: "Bill"
	customer_id: "5928549548188"
	customer_last_name: "Call"
	customer_order_count: "1"
	customer_phone: "999-999-9999"
	customer_province: "South Carolina"
	customer_province_code: "SC"
	customer_tags: ""
	customer_total_spent: "0.0"
	customer_zip: "22222"

  // The following fields are required 
	user_consent: "" // Use an empty string
	visitor_type: "logged_in" // "logged_in" || "guest"
	user_id : "XXXXX" // This is a unique user id. It should be persisted as long as possible ideally between user sessions
}
 * 
 */

let currentLocale = defaultLocale;

/**
 * It's critical that Elevar receives the user_data event first, then all other events.
 * Every non-user_data event gets added to a queue until we receive a user_data event.
 * Once this happens, we can cleanup our buffer and allow events to pass into the datalayer directly.
 */

export function addEvent(event: IEvent) {
  eventManager.pushEvent(event);
}

export function getUserProperties(
  data?: Pick<IUserProperties, "customer_email">
): IUserProperties {
  const emailObj = data?.customer_email
    ? { customer_email: data.customer_email }
    : {};
  return {
    user_consent: "",
    visitor_type: "guest",
    ...emailObj,
  };
}

export function updateLocaleCode(locale: LocaleCode) {
  currentLocale = locale;
}

export function getShopCurrencyCode() {
  return getLocale(currentLocale).currencyCode;
}

export function getImpressions(items: FormattedCollectionItem[]) {
  const impressions: IImpression[] = [];
  items.forEach((item, index) => {
    item.variants.forEach((variant) => {
      impressions.push({
        id: String(item.id),
        name: item.title,
        brand: item.vendor || "Thuma",
        category: item.productType || "",
        variant: variant.title as string,
        price: item.price,
        list: "",
        product_id: String(variant.productId ?? item.id),
        variant_id: String(variant.id),
        image: item.image?.src,
        position: index + 1,
        url: item.href,
      });
    });
  });
  return impressions;
}

export function createProduct(product: IPartialProduct): IProduct {
  return {
    ...product,
    category: product.category || "",
    brand: product.brand || "Thuma",
  };
}

const getCartQuantity = (items: LineItem[]) =>
  items.reduce((total, item) => total + item.quantity, 0).toString();

const getCartItemImpressions = (items: LineItem[]): IImpression[] =>
  items.map(
    (
      {
        quantity,
        cost: {
          subtotalAmount: { amount },
        },
        variant: {
          id: variantId,
          sku,
          title,
          image,
          product: {
            id: productId,
            vendor,
            productType,
            onlineStoreUrl,
            title: productName,
          },
        },
      },
      index
    ) => ({
      id: `${sku}`,
      name: productName,
      brand: vendor,
      category: productType,
      variant: title,
      price: amount,
      quantity: String(quantity),
      list: "",
      product_id: getShopifyId(productId),
      variant_id: getShopifyId(variantId),
      image: image?.src.replace(/https?:/, "") ?? "",
      position: index + 1,
      url: onlineStoreUrl ?? "",
    })
  );

export const formatRecommendedProduct = (
  product: RecommendedProduct & { position: number },
  selectedVariant: RecommendedProduct["defaultVariant"],
  list: string
) => ({
  id: selectedVariant.sku,
  name: product.title,
  brand: product.brand,
  category: product.category,
  variant: selectedVariant.title,
  price: selectedVariant.price,
  quantity: "1",
  list,
  product_id: selectedVariant.productId,
  variant_id: selectedVariant.id,
  compare_at_price: "",
  image: selectedVariant.images[0].url,
  position: product.position + 1,
});

const getRecommendedProductImpressions = (
  products: RecommendedProduct[]
): IImpression[] =>
  products.map(
    (
      {
        defaultVariant: {
          sku: id,
          id: variant_id,
          title: variant,
          price,
          productId: product_id,
          images: [{ url: image }],
        },
        title: name,
        brand,
        category,
        slug,
      },
      index
    ) => ({
      id,
      name,
      brand,
      category,
      price,
      variant_id,
      product_id,
      position: index + 1,
      list: "more-to-love",
      image,
      variant,
      url: makeProductUrl(slug, variant_id),
    })
  );

const formatMergedProducts = ({
  product,
  variant,
  list = "",
}: {
  product: MergedProduct;
  variant: MergedProductVariant;
  list?: string;
}) => [
  {
    id: String(variant.sku),
    name: product.title,
    brand: product.vendor,
    category: product.productType,
    variant: variant.title,
    price: String(variant.price),
    inventory: variant.available,
    quantity: "1",
    list,
    product_id: String(variant.productId),
    variant_id: String(variant.id),
    compare_at_price: "0.0",
    image: variant.images[0].url,
  },
];

export function formatLineItems(items: LineItem[] | undefined): IProduct[] {
  if (!items) return [];

  return items.map((item) => ({
    id: item.variant.sku,
    name: item.variant.title,
    brand: item.variant.product.vendor,
    category: item.variant.product.productType,
    variant: item.variant.title,
    price: item.variant.price.amount,
    quantity: item.quantity.toString(),
    list: "",
    product_id: getShopifyId(item.variant.product?.id),
    variant_id: getShopifyId(item.variant.id),
    compare_at_price: item.variant.compareAtPrice?.amount || "",
    image: item.variant.image?.src || "",
  }));
}

function formatListField(
  item:
    | AddToCartPayload["lineItems"][number]
    | RemoveFromCartPayload["payload"][number]
    | UpdateCartPayload["payload"][number]
): string {
  let list = "";
  const customAttributes = (item as AddToCartPayload["lineItems"][number])
    ?.customAttributes;
  if (customAttributes) {
    list = customAttributes.find((attr) => attr.key === "_list")?.value || "";
  }
  return list;
}

export function formatMutationLineItems(
  items:
    | AddToCartPayload["lineItems"]
    | RemoveFromCartPayload["payload"]
    | UpdateCartPayload["payload"]
): IProduct[] {
  return items.map((item) => {
    if ("lineItem" in item) {
      return {
        id: item.lineItem.variant.sku,
        name: item.lineItem.variant.product.title,
        brand: item.lineItem.variant.product.vendor,
        category: item.lineItem.variant.product.productType || "",
        variant: item.lineItem.variant.title,
        price: item.lineItem.variant.price.amount,
        quantity: ("quantity" in item
          ? Math.abs(item.quantity - item.lineItem.quantity)
          : item.lineItem.quantity
        ).toString(),
        list: formatListField(item),
        product_id: getShopifyId(item.lineItem.variant.product?.id),
        variant_id: getShopifyId(item.lineItem.variant.id),
        compare_at_price: item.lineItem?.variant.compareAtPrice?.amount || "",
        image: item.lineItem.variant.image?.src.replace(/https?:/, "") || "",
      };
    }
    return {
      id: item.variant.sku,
      name: item.product?.title,
      brand: item.product.vendor || "",
      category: item.product.productType || "",
      variant: item.variant.title,
      price: item.variant.price,
      quantity: item.quantity.toString(),
      list: formatListField(item),
      product_id: item.product.id,
      variant_id: item.variant.id,
      compare_at_price: item.variant.compareAtPrice || "",
      image: item.variant.images[0]?.url || "",
    };
  });
}

export function addCartInitializedEvent({
  cartTotal,
  items,
}: {
  cartTotal: string;
  items: LineItem[];
}) {
  const cartInitializedPayload: IInitCart = {
    event: "dl_init_cart",
    user_properties: getUserProperties(),
    cart_quantity: getCartQuantity(items),
    cart_total: cartTotal,
    ecommerce: {
      currencyCode: getShopCurrencyCode(),
      actionField: { list: "Shopping Cart" },
      impressions: getCartItemImpressions(items),
    },
  };
  addEvent(cartInitializedPayload);
}

export function addViewCartEvent({
  cartTotal,
  items,
}: {
  cartTotal: string;
  items: LineItem[];
}) {
  const viewCartPayload: IViewCart = {
    event: "dl_view_cart",
    user_properties: getUserProperties(),
    cart_quantity: getCartQuantity(items),
    cart_total: cartTotal,
    ecommerce: {
      actionField: { list: "Shopping Cart" },
      currencyCode: getShopCurrencyCode(),
      impressions: getCartItemImpressions(items),
    },
  };
  addEvent(viewCartPayload);
}
export function addViewProductEvent({
  product,
  variant,
  list,
}: {
  product: MergedProduct;
  variant: MergedProductVariant;
  list: string;
}) {
  const viewProductPayload: IViewProduct = {
    event: "dl_view_item",
    user_properties: getUserProperties(),
    ecommerce: {
      currencyCode: getShopCurrencyCode(),
      detail: {
        products: formatMergedProducts({ product, variant }),
        actionField: { list, action: "detail" },
      },
    },
  };
  addEvent(viewProductPayload);
}

export function addViewCollectionEvent(
  items: FormattedCollectionItem[] | RecommendedProduct[],
  list: string
) {
  const item = items[0];
  let impressions: IImpression[] = [];
  if (item) {
    if ("defaultVariant" in item) {
      impressions = getRecommendedProductImpressions(
        items as RecommendedProduct[]
      ).map((i) => ({ ...i, list }));
    } else {
      impressions = getImpressions(items as FormattedCollectionItem[]).map(
        (i) => ({ ...i, list })
      );
    }
  }

  const viewCollectionPayload: IViewCollection = {
    event: "dl_view_item_list",
    user_properties: getUserProperties(),
    ecommerce: {
      impressions,
      currencyCode: getShopCurrencyCode(),
    },
  };
  addEvent(viewCollectionPayload);
}

export function addBaseEvent(
  lineItems: LineItem[] | undefined,
  subtotal = "0"
) {
  // Invalidate Elevar context on route change
  window.ElevarInvalidateContext?.();

  const baseLayerPayload: IBaseLayer = {
    event: "dl_user_data",
    cart_total: subtotal,
    user_properties: getUserProperties(),
    ecommerce: {
      cart_contents: {
        products: formatLineItems(lineItems),
      },
      currencyCode: getShopCurrencyCode(),
    },
  };
  addEvent(baseLayerPayload);
}

export function addRouteChangeEvent() {
  const routeChangePayload: IRouteChange = {
    event: "dl_route_change",
  };
  addEvent(routeChangePayload);
}

export function addProductSelectEvent(product: IPartialProduct, list: string) {
  const selectItemPayload: IItemSelect = {
    event: "dl_select_item",
    user_properties: getUserProperties(),
    ecommerce: {
      currencyCode: getShopCurrencyCode(),
      click: {
        actionField: {
          list,
          action: "click",
        },
        products: [createProduct(product)],
      },
    },
  };
  addEvent(selectItemPayload);
}

export function addSubscribeEvent(
  data: IEmailSignupData["email_signup"] &
    Pick<IUserProperties, "customer_email">
) {
  const signupPayload: ISignupSelect & IEmailSignupData = {
    event: "dl_subscribe",
    user_properties: getUserProperties(data),
    lead_type: "email",
    email_signup: {
      klaviyo_list_id: data.klaviyo_list_id,
      subscriber_source: data.subscriber_source,
    },
  };
  addEvent(signupPayload);
}

export function addBISSignupEvent(
  data: IBISSignupData["bis_signup"] & Pick<IUserProperties, "customer_email">
) {
  const signupPayload: ISignupSelect & IBISSignupData = {
    event: "dl_bis_sign_up",
    lead_type: "email",
    user_properties: getUserProperties(data),
    bis_signup: {
      product_variant: data.product_variant,
    },
  };
  addEvent(signupPayload);
}

export function addToCartEvent({
  user,
  items,
  location,
}: {
  user?: Pick<IUserProperties, "customer_email">;
  items: AddToCartPayload["lineItems"] | UpdateCartPayload["payload"];
  location: string;
}) {
  const addToCartPayload: IAddToCart = {
    event: "dl_add_to_cart",
    user_properties: getUserProperties(user),
    ecommerce: {
      currencyCode: getShopCurrencyCode(),
      add: {
        actionField: { list: formatListField(items[0]) },
        products: formatMutationLineItems(items),
      },
    },
    custom: {
      location,
    },
  };
  addEvent(addToCartPayload);
}

export function removeFromCartEvent({
  user,
  items,
}: {
  user?: Pick<IUserProperties, "customer_email">;
  items: RemoveFromCartPayload["payload"] | UpdateCartPayload["payload"];
}) {
  const removeFromCartPayload: IRemoveFromCart = {
    event: "dl_remove_from_cart",
    user_properties: getUserProperties(user),
    ecommerce: {
      currencyCode: getShopCurrencyCode(),
      remove: {
        actionField: {
          list: "",
        },
        products: formatMutationLineItems(items),
      },
    },
  };
  addEvent(removeFromCartPayload);
}

export function addViewSearchPageEvent({
  query,
  originalQuery,
  items,
}: {
  query: string;
  originalQuery: string;
  items: FormattedCollectionItem[];
}) {
  const viewSearchPagePayload: IViewSearchPage = {
    event: "dl_view_search_results",
    user_properties: getUserProperties(),
    search: {
      search_term: query,
      search_input: originalQuery,
      number_of_results: `${items.length}`,
      search_results: items.map((item) => `shopify_US_${item.id}_${item.key}`), // key is variant id
    },
    ecommerce: {
      actionField: { list: "search results" },
      currencyCode: getShopCurrencyCode(),
      impressions: getImpressions(items),
    },
  };

  // Reset the previous impressions object
  window.ElevarDataLayer.push(function clearImpressions() {
    this.set("ecommerce.impressions", undefined);
  });
  addEvent(viewSearchPagePayload);
}

export function addSearchProductSelectEvent(
  product: IPartialProduct,
  intellisuggestData: string,
  intellisuggestSignature: string,
  searchTerm: string,
  searchInput: string,
  list: string
) {
  const selectItemPayload: ISearchResultsItemSelect = {
    event: "dl_select_item",
    user_properties: getUserProperties(),
    search: {
      search_term: searchTerm,
      search_input: searchInput,
      intellisuggest_data: intellisuggestData,
      intellisuggest_signature: intellisuggestSignature,
    },
    ecommerce: {
      currencyCode: getShopCurrencyCode(),
      click: {
        actionField: {
          list,
          action: "click",
        },
        products: [createProduct(product)],
      },
    },
  };
  addEvent(selectItemPayload);
}

export function addViewMarketingCaptureEvent({
  marketing_channel,
}: {
  marketing_channel: string;
}) {
  const viewMarketingCapturePayload: IViewMarketingCapture = {
    event: "dl_view_marketing_capture",
    user_properties: getUserProperties(),
    marketing_channel,
  };
  addEvent(viewMarketingCapturePayload);
}

export function addB2BSubmission(email: string) {
  const b2bSubmissionPayload: ISubmitB2BForm = {
    event: "dl_submit_b2b_form",
    user_properties: getUserProperties({ customer_email: email }),
  };

  addEvent(b2bSubmissionPayload);
}

/** Base (generic) Heap event to send through any and all properties */
export function addHeapEvent(
  name: string,
  properties: Record<string, unknown>
) {
  addEvent({
    event: "generic_heap_track",
    data: {
      event: name,
      properties: {
        ...properties,
        // Include keys where value is not undefined
        [`${name}_keys`]: Object.entries(properties)
          .filter(([, value]) => value !== undefined)
          .map(([key]) => key),
      }, // Include keys to avoid GTM merging properties from unrelated events
    },
  });
}

export function addHeapViewRecommendationListEvent(
  items: RecommendedProduct[],
  location: "cart" | "page"
) {
  const impressionList = getRecommendedProductImpressions(items);
  const impressionObject = impressionList.reduce((acc, impression, index) => {
    Object.keys(impression).forEach((key) => {
      if (!["list"].includes(key))
        acc[`${key}_${index}`] = impression[key as keyof IImpression];
    });
    return acc;
  }, {} as Record<string, unknown>);

  addHeapEvent("view_recommendation_list", {
    ...impressionObject,
    location,
  });
}

export function addHeapOnCollectionListSlideChange(
  name: string,
  fromIndex: number,
  toIndex: number
) {
  addHeapEvent("collection_list_slide_change", {
    name,
    fromIndex,
    toIndex,
    swipeDirection: fromIndex < toIndex ? "right" : "left",
  });
}

export function addHeapOnCardCarouselItemClick(
  name: string,
  parentName?: string | null,
  clickedElement?: string | null
) {
  addHeapEvent("card_carousel_item_click", {
    name,
    parentName,
    clickedElement,
  });
}

export function getLocationFromName(
  location: string | undefined,
  name: string
) {
  if (location) return location;

  // TODO: keep up to date if we add sections that can be used
  // for multiple intents
  const locationIntents = [
    "Read More",
    "Shop the Look",
    "Key Elements",
    "Design Philosophy",
    "Collection Section",
    "Recommended Products - PDP",
    "Recommended Products - Cart",
    "Recommended Products - Curated",
    "Shop by Category",
    "Look Book",
    "Dimensions",
    "Sustainability",
  ];

  const intent = locationIntents.find((i) =>
    name.toLowerCase().includes(i.toLowerCase())
  );
  return intent || name;
}

export function addHeapOnCardCarouselInteracted(
  name: string,
  direction: string,
  fromIndex: number,
  toIndex: number,
  interactionType: "button click" | "swipe",
  rawLocation?: string
) {
  const location = getLocationFromName(rawLocation, name);

  addHeapEvent("card_carousel_interacted", {
    name,
    direction,
    fromIndex,
    toIndex,
    location,
    interactionType,
  });
}

export function addHeapOnRichtextCTAClick({
  currentObject,
  parentObject,
}: {
  currentObject?: GenericObjectInfo;
  parentObject?: GenericObjectInfo;
}) {
  if (currentObject) {
    addHeapEvent("richtext_cta_click", {
      name: currentObject?.name,
      id: currentObject?.id,
      parentName: parentObject?.name,
      parentId: parentObject?.id,
    });
  }
}

export function addHeapViewHighlightSection(name: string, id: string) {
  addHeapEvent("highlight_section_viewed", {
    name,
    id,
  });
}

export function addHeapRecommendedItemClick(
  productName: string,
  variantId: string
) {
  addHeapEvent("recommended_item_click", {
    productName,
    variantId,
  });
}

export function addHeapModalOpened(name: string, buttonText: string) {
  addHeapEvent("modal_opened", {
    name,
    buttonText,
  });
}

export function addHeapAccordionPaneInteracted(params: {
  accordionSectionName: string;
  accordionID: string;
  accordionTitle: string;
  accordionPosition: number;
  interactionType: "opened" | "closed";
  visibleOnPageload: "yes" | "no";
}) {
  addHeapEvent("accordion_pane_interacted", params);
}

export function addHeapGroupChanged(optionName: string, value: string) {
  addHeapEvent("option_group_changed", {
    optionName,
    value,
  });
}

export function addHeapReviewsBottomlineClicked(productName: string) {
  addHeapEvent("reviews_bottomline_click", {
    productName,
  });
}

export function addHeapProductCarouselInteraction(
  fromIndex: number,
  toIndex: number
) {
  const direction = fromIndex < toIndex ? "right" : "left";
  addHeapEvent("product_carousel_interaction", {
    direction,
    fromIndex,
    toIndex,
  });
}

export function addHeapOptionModalValueChanged(name: string, value: string) {
  addHeapEvent("option_modal_value_changed", { name, value });
}

export function addHeapNavigationItemClick({
  style,
  isMobile,
  hasImage,
  level,
  title,
  version,
  href,
}: {
  style?: string;
  isMobile: boolean;
  hasImage: boolean;
  level: number;
  title?: string | null;
  version: string;
  href?: string | null;
}) {
  addHeapEvent("navigation_item_click", {
    style,
    isMobile,
    hasImage,
    level,
    title,
    version,
    href,
    isCollection: href?.includes("/collections/"),
    isProduct: href?.includes("/products/"),
  });
}

// track event when mobile navigation is opened
export function addHeapMobileNavigationOpened() {
  addHeapEvent("mobile_navigation_opened", {});
}

// track event when desktop L1s are expanded
export function addHeapDesktopNavigationExpanded() {
  addHeapEvent("desktop_navigation_expanded", {});
}

// track how long a user spent navigating
export function addHeapNavigationDuration(
  duration: number,
  didNavigate: boolean
) {
  addHeapEvent("mobile_navigation_duration", {
    duration,
    didNavigate,
  });
}

export function addHeapShopTheLookSectionViewed(name: string) {
  addHeapEvent("shop_the_look_section_viewed", { name });
}

export function addHeapShopTheLookProductListToggled(
  name: string,
  state: "opened" | "closed"
) {
  addHeapEvent("shop_the_look_product_list_toggled", { name, state });
}

export function addHeapShopTheLookMarkerClicked(
  name: string,
  productName: string,
  variantId: string,
  variantTitle: string,
  variantSku: string
) {
  addHeapEvent("shop_the_look_marker_clicked", {
    name,
    productName,
    variantId,
    variantTitle,
    variantSku,
  });
}

export function addHeapShopTheLookProductCardClicked(
  name: string,
  productName: string,
  variantId: string,
  variantTitle: string,
  position: number
) {
  addHeapEvent("shop_the_look_product_card_clicked", {
    name,
    position,
    productName,
    variantId,
    variantTitle,
  });
}

export function addHeapContentExpanded(
  sectionName: string,
  buttonText: string,
  expanded: boolean
) {
  const interactionType = expanded ? "expanded" : "collapsed";
  addHeapEvent("content_expanded", {
    sectionName,
    interactionType,
    buttonText,
  });
}

export function addHeapBackToTopClicked({
  name,
  id,
  location,
}: {
  name: string;
  id?: string;
  location: string;
}) {
  addHeapEvent("back_to_top_clicked", { name, id, location });
}

export function addHeapOnGridItemClick(
  name: string,
  parentName?: string | null,
  clickedElement?: string | null
) {
  addHeapEvent("grid_item_click", {
    name,
    parentName,
    clickedElement,
  });
}

export function addHeapViewGridSection(name: string, id: string) {
  addHeapEvent("grid_section_viewed", {
    name,
    id,
  });
}

export function addHeapProductTileClicked(props: IProductTileProps) {
  addHeapEvent("product_tile_clicked", props);
}

export function addHeapProductTileViewed(props: IProductTileProps) {
  addHeapEvent("product_tile_viewed", props);
}

export function addHeapContentModuleViewed(props: IContentModuleProps) {
  addHeapEvent("content_module_viewed", {
    ...props,
    moduleTitle: props.moduleTitle || "",
  });
}

export function addHeapContentModuleClicked(
  props: IContentModuleProps & { elementName?: string; elementType?: string }
) {
  addHeapEvent("content_module_clicked", props);
}

export function addHeapProductListNavigationClicked({
  productListNavElementType,
  productListNavElementName,
  productListNavElementPosition,
  visibleOnPageload,
}: {
  productListNavElementType:
    | "carousel_card_image"
    | "carousel_card_cta"
    | "carousel_menu";
  productListNavElementName: string;
  productListNavElementPosition: number;
  visibleOnPageload: "yes" | "no";
}) {
  addHeapEvent("product_list_navigation_clicked", {
    productListNavElementType,
    productListNavElementName,
    productListNavElementPosition,
    visibleOnPageload,
  });
}

export function addHeapProductListNavigationInteracted({
  direction,
  fromIndex,
  interactionType,
  toIndex,
  fromName,
  toName,
}: {
  direction: "left" | "right";
  interactionType: "button click" | "swipe";
  fromIndex?: number;
  toIndex?: number;
  fromName?: string;
  toName?: string;
}) {
  addHeapEvent("product_list_navigation_interacted", {
    direction,
    interactionType,
    fromIndex,
    toIndex,
    fromName,
    toName,
  });
}

export function addHeapProductTileSwatchClicked({
  swatchPosition,
  swatchName,
  swatchSku,
  swatchVariantId,
  defaultSwatchName,
  defaultSwatchSku,
  defaultSwatchVariantId,
  defaultSwatchPosition,
}: {
  swatchPosition: number;
  swatchName: string;
  swatchSku: string;
  swatchVariantId: string;
  defaultSwatchName?: string;
  defaultSwatchSku: string;
  defaultSwatchVariantId: string;
  defaultSwatchPosition: number;
}) {
  addHeapEvent("product_tile_swatch_clicked", {
    swatchPosition,
    swatchName,
    swatchSku,
    swatchVariantId,
    defaultSwatchName,
    defaultSwatchSku,
    defaultSwatchVariantId,
    defaultSwatchPosition,
  });
}
