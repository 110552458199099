import { Document } from "@contentful/rich-text-types";
import classNames from "classnames";

import { Heading } from "../Heading";
import { RichText } from "../RichText";
import { Text } from "../Text";

interface TextSectionProps {
  title?: string;
  body?: Document;
  align?: "left" | "center";
  desktopAlign?: "left" | "center";
}

export function TextSection({
  title,
  body,
  align = "center",
  desktopAlign = align,
}: TextSectionProps) {
  return (
    <section
      className={classNames(
        "gap-y-0.5 flex px-6 full:px-8 mx-auto max-w-screen-king w-full",
        {
          "text-left justify-start": align === "left",
          "text-center justify-center": align === "center",
          "full:text-left full:justify-start": desktopAlign === "left",
          "full:text-center full:justify-center": desktopAlign === "center",
        }
      )}
    >
      <div className="max-w-160">
        {title && (
          <Heading as="h3" variant={{ base: "h4", desktop: "h3" }}>
            {title}
          </Heading>
        )}
        {body && (
          <Text
            className={classNames({
              "mt-0.5": title,
            })}
          >
            <RichText richTextResponse={body} />
          </Text>
        )}
      </div>
    </section>
  );
}
