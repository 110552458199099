import { Fragment, useContext } from "react";
import { LinkContext } from "src/components/Link";

import { Asset } from "../Asset/Asset";
import { AssetType } from "../Asset/types";
import { CTA } from "../CTA";
import { Eyebrows } from "../Eyebrows/Eyebrows";
import { Heading } from "../Heading";
import { Text } from "../Text";

type CardProps = {
  asset: AssetType;
  eyebrows?: string | string[];
  subheading: string;
  paragraph?: string;
  href?: string;
  cta?: {
    text: string;
    href: string;
  };
};

export function Card({
  asset,
  eyebrows,
  subheading,
  paragraph,
  cta,
  href,
}: CardProps) {
  const LinkElement = useContext(LinkContext);

  const Wrapper = href ? LinkElement : Fragment;

  return (
    <Wrapper {...(href && { href })}>
      <div className="grid">
        <Asset asset={asset} />
        <div className="flex flex-col mt-2.5">
          {eyebrows && (
            <Eyebrows eyebrows={eyebrows} className=" mb-0.5 full:mb-1" />
          )}
          <Heading as="h6">{subheading}</Heading>
          {paragraph && (
            <Text variant="body" className="mt-0.5 full:mt-1.5">
              {paragraph}
            </Text>
          )}
          {cta && (
            <CTA href={cta.href} className="mt-4.5">
              {cta.text}
            </CTA>
          )}
        </div>
      </div>
    </Wrapper>
  );
}
