import * as React from "react";

import { cn } from "../../../utils/tailwind-merge";
import { Heading } from "../Heading";

const mobileColumnClasses: Record<number, string> = {
  1: "grid-cols-1",
  2: "grid-cols-2",
  3: "grid-cols-3",
  4: "grid-cols-4",
};

const tabletColumnClasses: Record<number, string> = {
  1: "twinXL:grid-cols-1",
  2: "twinXl:grid-cols-2",
  3: "twinXl:grid-cols-3",
  4: "twinXl:grid-cols-4",
};

const desktopColumnClasses: Record<number, string> = {
  1: "full:grid-cols-1",
  2: "full:grid-cols-2",
  3: "full:grid-cols-3",
  4: "full:grid-cols-4",
};

export type ColumnLimit = keyof typeof mobileColumnClasses;

interface Props {
  children: React.ReactNode;
  title?: string;
  fullBleed?: boolean;
  columns: {
    mobile: ColumnLimit;
    tablet?: ColumnLimit;
    desktop?: ColumnLimit;
  };
  className?: string;
}

export function Grid({
  columns,
  title,
  className,
  fullBleed,
  children,
}: Props) {
  return (
    <section
      className={cn(
        {
          "px-6 mx-auto max-w-screen-king full:px-8": !fullBleed,
        },
        className
      )}
    >
      {title ? (
        <Heading as="h5" className="mb-4 full:mb-6">
          {title}
        </Heading>
      ) : null}
      <div
        className={cn(
          "grid gap-4 full:gap-y-8",
          mobileColumnClasses[columns.mobile],
          columns.tablet && tabletColumnClasses[columns.tablet],
          columns.desktop && desktopColumnClasses[columns.desktop]
        )}
      >
        {children}
      </div>
    </section>
  );
}
