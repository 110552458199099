import { Fragment, useContext } from "react";
import classNames from "classnames";
import { LinkContext } from "src/components/Link";

import { CTA } from "../CTA";
import { Eyebrows } from "../Eyebrows/Eyebrows";
import { Heading } from "../Heading";
import { OverlayMedia, OverlayMediaProps } from "../OverlayMedia";

type TextProps = {
  eyebrows?: string | string[];
  header?: string;
  subcopy?: string;
  verticalAlignment?: "center" | "bottom";
  horizontalAlignment?: "left" | "center";
  href?: string;
  ctaList?: {
    href: string;
    text: string;
  }[];
  textColor?: string;
};

export type HeroProps = TextProps & OverlayMediaProps;

export function Hero({
  eyebrows,
  header,
  subcopy,
  verticalAlignment = "center",
  horizontalAlignment = "center",
  href,
  ctaList,
  asset,
  overlayOpacity,
  overlayColor,
  textColor = "white",
}: HeroProps) {
  const LinkElement = useContext(LinkContext);
  const Wrapper = href ? LinkElement : Fragment;
  return (
    <Wrapper href={href}>
      <OverlayMedia
        as="section"
        asset={asset}
        overlayOpacity={overlayOpacity}
        overlayColor={overlayColor}
        textColor={textColor}
        verticalAlignment={verticalAlignment}
        horizontalAlignment={horizontalAlignment}
      >
        <div
          className={classNames(
            "text-left flex flex-col items-start max-w-160",
            {
              "text-center items-center": horizontalAlignment === "center",
            }
          )}
        >
          {eyebrows && <Eyebrows eyebrows={eyebrows} className="mb-3" />}
          {header && (
            <Heading as="h1" variant={{ base: "h3", desktop: "h1" }}>
              {header}
            </Heading>
          )}
          {subcopy && (
            <Heading
              as="h5"
              variant={{ base: "h6", desktop: "h5" }}
              className="mt-0.5"
            >
              {subcopy}
            </Heading>
          )}
          {ctaList && (
            <div className="flex gap-x-4 w-fit mt-4.5">
              {ctaList?.map((cta) => (
                <CTA key={cta.href} href={cta.href}>
                  {cta.text}
                </CTA>
              ))}
            </div>
          )}
        </div>
      </OverlayMedia>
    </Wrapper>
  );
}
