import { useContext, useMemo } from "react";
import { useRouter } from "next/router";

import { FeaturedProduct } from "../components/Navigation/types";
import { useFeaturedProductsContext } from "../context/FeaturedProductsContext";
import { useOptionalProductContext } from "../context/ProductContext";
import { SessionIdContext } from "../context/SessionIdContext";
import type { Cart } from "../services/cart/types";
import { ProductCatalog } from "../services/catalog/types";
import { CrossingMindsItem } from "../services/crossingminds/api";
import { RebuyProduct } from "../services/recommended-products/types";
import { getShopifyId } from "../services/util/shopifyID";
import { isTruthy } from "../util";
import { LocaleCode } from "../util/locale";

import { useTypedQuery } from "./useTypedQuery";

export type RecommendedProduct = ReturnType<
  typeof formatRebuyRecommendations
>[0];

export type RecommendedProductOption = RecommendedProduct["options"][0];

export type RecommendedProductVariant = RecommendedProduct["variants"][0];

interface RecommendationFormatterParams<
  T extends RebuyProduct | CrossingMindsItem | FeaturedProduct
> {
  productCatalogData: ProductCatalog;
  items: T[];
  cart?: Cart;
}

function formatRebuyRecommendations({
  productCatalogData,
  items,
  cart,
}: RecommendationFormatterParams<RebuyProduct>) {
  return items
    .map((product) => {
      const displayData = productCatalogData[product.id];
      const isInCart = cart?.lineItems.some(
        (line) => product.id === +getShopifyId(line.variant?.product?.id)
      );

      if (!displayData || isInCart) {
        return undefined;
      }

      const variants = product.variants
        .map(({ id, title }) => {
          const variant = displayData.variants[id];
          if (!variant) return undefined;
          return { ...variant, title };
        }) // map to catalog variants
        .filter(isTruthy); // only include catalog variants

      const defaultVariant = variants.find(
        (o) => o.id === displayData.defaultVariant
      ) ??
        variants[0] ?? {
          ...displayData.variants[displayData.defaultVariant],
          title: displayData.productTitle,
        };

      return {
        defaultVariant,
        id: product.id.toString(),
        options: displayData.options,
        slug: displayData.slug,
        title: displayData.productTitle,
        variants,

        // fields for elevar
        brand: product.vendor,
        category: product.product_type,
      };
    })
    .filter(isTruthy);
}

function formatCMRecommendations({
  productCatalogData,
  items,
  cart,
}: RecommendationFormatterParams<CrossingMindsItem>) {
  return items
    .map(({ productID, variantID, ...item }) => {
      const catalogProduct = productCatalogData[productID];
      const isInCart = cart?.lineItems.some(
        (line) => productID === getShopifyId(line.variant?.product?.id)
      );

      const catalogVariant = catalogProduct?.variants[variantID];

      if (!catalogProduct || !catalogVariant || isInCart) return undefined;

      return {
        defaultVariant: catalogVariant,
        id: productID,
        options: catalogProduct.options,
        slug: catalogProduct.slug,
        title: catalogProduct.productTitle,
        variants: Object.values(catalogProduct.variants),
        brand: item.brand,
        category: item.category,
      };
    })
    .filter(isTruthy);
}

function formatFeaturedProducts({
  productCatalogData,
  items,
  cart,
}: RecommendationFormatterParams<FeaturedProduct>) {
  return items
    .map(({ id }) => {
      const catalogProduct = productCatalogData[id];
      const isInCart = cart?.lineItems?.some(
        (line) => id === getShopifyId(line.variant?.product?.id)
      );

      if (!catalogProduct || isInCart) return undefined;

      const defaultVariant =
        catalogProduct.variants[catalogProduct.defaultVariant];
      return {
        defaultVariant,
        id,
        options: catalogProduct.options,
        slug: catalogProduct.slug,
        title: catalogProduct.productTitle,
        variants: Object.values(catalogProduct.variants),
        // Fields for elevar
        brand: "thuma",
        category: "",
      };
    })
    .filter(isTruthy);
}

export interface UseRecommendedProductsQueryProps {
  rulesetId: string;
}

export default function useRecommendedProductsQuery({
  rulesetId,
}: UseRecommendedProductsQueryProps) {
  const router = useRouter();
  const {
    data: cartData,
    isLoading: isLoadingCart,
    isError: isCartError,
  } = useTypedQuery(["cart"]);
  const cart = cartData ?? undefined;
  const productContext = useOptionalProductContext();
  const sessionId = useContext(SessionIdContext);

  const crossingMindsResponse = useTypedQuery(
    [
      "recommendations",
      {
        variantId: productContext?.variant?.id,
        sessionId,
        locale: router.locale as LocaleCode,
      },
      cart,
    ],
    {
      enabled: !isLoadingCart,
    }
  );
  const rebuyResponse = useTypedQuery(
    [
      "recommendedProducts",
      {
        productId: productContext?.variant?.productId,
        variantId: productContext?.variant?.id,
        rulesetId,
        cart,
      },
    ],
    {
      enabled: !isLoadingCart,
    }
  );
  const featuredProducts = useFeaturedProductsContext();
  const featuredProductIds = featuredProducts.map((product) => product.id);

  const productIds = crossingMindsResponse.data?.shouldUseCM
    ? crossingMindsResponse.data.items.map((item) => item.productID)
    : rebuyResponse.data?.map((product) => product.id.toString());

  const recommendedProductIds = [...(productIds ?? []), ...featuredProductIds];

  const { data: productCatalogData, isLoading: isLoadingCatalog } =
    useTypedQuery([
      "catalogProducts",
      router.locale as LocaleCode,
      ...recommendedProductIds,
    ]);

  const data = useMemo(
    () => {
      if (!productCatalogData) return [];

      // Group A
      if (crossingMindsResponse.data?.shouldUseCM)
        return formatCMRecommendations({
          productCatalogData,
          cart,
          items: crossingMindsResponse.data.items,
        });

      // Group B
      if (rebuyResponse.data && rebuyResponse.data.length)
        return formatRebuyRecommendations({
          productCatalogData,
          cart,
          items: rebuyResponse.data,
        });

      // Group C
      return formatFeaturedProducts({
        productCatalogData,
        cart,
        items: featuredProducts,
      });
    },
    // cart is removed from the dependency array because it is also a dependency for the recommendedProducts query.
    // This prevents multiple transformations from happening until the data is "complete".
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      featuredProducts,
      productCatalogData,
      rebuyResponse.data,
      crossingMindsResponse.data,
    ]
  );

  return {
    data,
    isLoading:
      isLoadingCart ||
      isLoadingCatalog ||
      rebuyResponse.isLoading ||
      crossingMindsResponse.isLoading,
    isError:
      isCartError || rebuyResponse.isError || crossingMindsResponse.isError,
  };
}
