import { RefObject } from "react";

export function paginate(
  direction: "left" | "right",
  ref: RefObject<HTMLDivElement>
) {
  if (!ref.current) return;
  const scrollContainerBoundClientRect = ref.current.getBoundingClientRect();

  // Note: This relies on the scroll container having no vertical padding. It may stop working if added.
  const elementsFromPoint = Array.from(
    document.elementsFromPoint(
      scrollContainerBoundClientRect.x + 24, // offset 24px is for the spacing between the jump links
      scrollContainerBoundClientRect.y + 4 // 4px is the vertical padding of the scroll container
    )
  );
  const scrollChildren = Array.from(ref.current.children);
  const leftMostChildInViewIndex = scrollChildren.findIndex((node) =>
    elementsFromPoint.includes(node)
  );
  const correctedLeftMostChildInViewIndex =
    leftMostChildInViewIndex === -1 ? 0 : leftMostChildInViewIndex;

  const leftMostChildInView = scrollChildren[correctedLeftMostChildInViewIndex];
  if (direction === "left") {
    const leftMostChildInViewBoundingBox =
      leftMostChildInView.getBoundingClientRect();

    const diff =
      leftMostChildInViewBoundingBox.x - scrollContainerBoundClientRect.x;
    const isLeftMostChildPartiallyInView = diff < 0;

    if (isLeftMostChildPartiallyInView)
      ref.current.scrollBy({
        left: diff,
        behavior: "smooth",
      });
    else {
      const firstOutOfViewChildOnTheLeft =
        scrollChildren[correctedLeftMostChildInViewIndex - 1];
      ref.current.scrollBy({
        left: -firstOutOfViewChildOnTheLeft.clientWidth,
        behavior: "smooth",
      });
    }
  } else
    ref.current.scrollBy({
      left: leftMostChildInView.clientWidth,
      behavior: "smooth",
    });
}

export function getScrollDirectionIndices(
  ref: RefObject<HTMLDivElement>,
  startingPosition: number
) {
  if (!ref.current) return { fromIndex: undefined, toIndex: undefined };
  const scrollContainerBoundClientRect = ref.current.getBoundingClientRect();
  const elementsFromPoint = Array.from(
    document.elementsFromPoint(
      scrollContainerBoundClientRect.x + 24, // offset 24px is for the spacing between the jump links
      scrollContainerBoundClientRect.y + 4 // 4px is the vertical padding of the scroll container
    )
  );
  const scrollChildren = Array.from(ref.current.children);
  const leftMostChildInViewIndex = scrollChildren.findIndex((node) =>
    elementsFromPoint.includes(node)
  );

  let accumulatedWidth = 0;
  let fromIndex = 0;

  for (let i = 0; i < scrollChildren.length; i += 1) {
    accumulatedWidth += scrollChildren[i].clientWidth;
    if (accumulatedWidth > startingPosition) {
      fromIndex = i;
      break;
    }
  }

  return {
    fromIndex,
    toIndex:
      leftMostChildInViewIndex === -1 ? undefined : leftMostChildInViewIndex,
  };
}
