import { Grid } from "src/components/DesignSystem/Grid/Grid";
import { useBreakpoints } from "src/lib/hooks/useBreakpoints";

import {
  ICard2Dot5,
  IGrid2Dot5,
  IPostCard,
} from "../../../../../../@types/generated/contentful";

import CardSaddle from "./components/CardSaddle";
import PostCardSaddle from "./components/PostCardSaddle";

function GridItem({
  content,
}: {
  content: IGrid2Dot5["fields"]["baseItems"][0];
}) {
  switch (content.sys.contentType.sys.id) {
    case "postCard":
      return <PostCardSaddle content={content as IPostCard} />;
    case "card2Dot5":
      return <CardSaddle content={content as ICard2Dot5} />;
  }
}

export function Grid2Dot5SectionSaddle({
  content: { fields },
}: {
  content: IGrid2Dot5;
}) {
  const { fullVisible } = useBreakpoints();
  const {
    gridConfig: {
      fields: { mobileColumns, tabletColumns, desktopColumns },
    },
    baseItems: items,
    desktopItems = items,
    title,
    fullBleedOnMobile,
  } = fields;

  return (
    <Grid
      title={title}
      columns={{
        mobile: mobileColumns,
        tablet: tabletColumns,
        desktop: desktopColumns,
      }}
      fullBleed={!fullVisible && fullBleedOnMobile}
    >
      {(fullVisible ? desktopItems : items).map((item) => (
        <GridItem key={item.sys.id} content={item} />
      ))}
    </Grid>
  );
}
