import { useMemo } from "react";
import { ContentModuleContext } from "src/lib/context/ContentModuleContext";

import {
  ICollectionList,
  IDualRichTextSection,
  ITextOverlayMedia,
} from "../../../../../@types/generated/contentful";
import { DualSections } from "../../../../components";

import CollectionListSaddle from "./CollectionListSaddle/CollectionListSaddle";
import TextOverlayMediaSaddle from "./TextOverlayMediaSaddle";
import { isCollectionListEntry, isTextOverlayMediaEntry } from "./utils";

function getElement(
  content: ITextOverlayMedia | ICollectionList | unknown,
  index?: number
) {
  if (isTextOverlayMediaEntry(content)) {
    return (
      <TextOverlayMediaSaddle
        content={content}
        isSection={false}
        lrtClassName="p-6 full:p-8"
      />
    );
  }
  if (isCollectionListEntry(content)) {
    return (
      <div className="flex items-center w-full h-full">
        <div className="w-full">
          <CollectionListSaddle
            className="!py-0 shop-the-look-collection-list"
            content={{
              ...content,
              fields: { ...content.fields, layout: "shop-the-look" },
            }}
            index={index}
          />
        </div>
      </div>
    );
  }
  return null;
}

export default function DualRichTextSectionSaddle({
  content: {
    sys: {
      id: sectionId,
      contentType: {
        sys: { id: contentType },
      },
    },
    fields: {
      name,
      leftTextOverlayMedia: left,
      rightTextOverlayMedia: right,
      flipOrderForDesktop,
    },
  },
  index,
}: {
  content: IDualRichTextSection;
  index: number;
}) {
  const moduleContextValue = useMemo(
    () => ({
      moduleEntryName: name,
      moduleEntryId: sectionId,
      moduleType: contentType,
      modulePosition: index + 1,
    }),
    [contentType, name, sectionId, index]
  );
  return (
    <ContentModuleContext.Provider value={moduleContextValue}>
      <DualSections
        key={sectionId}
        left={getElement(left)}
        right={getElement(right, index)}
        flipOrderForDesktop={flipOrderForDesktop}
      />
    </ContentModuleContext.Provider>
  );
}
