import { RefObject, useEffect, useRef, useState } from "react";

import { getScrollDirectionIndices, paginate } from "./utils";

export function useScrollHandler(
  ref: RefObject<HTMLDivElement>,
  onScroll?: ({
    direction,
    fromIndex,
    toIndex,
    interactionType,
  }: {
    direction: "left" | "right";
    interactionType: "button click" | "swipe";
    fromIndex?: number;
    toIndex?: number;
  }) => void
) {
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const isButtonScroll = useRef(false);
  const scrollPosition = useRef(ref.current?.scrollLeft ?? 0);
  // Timeout to only call onScroll when the scroll has finished, due to scroll snapping / smooth scrolling
  const isPaginatingTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollRef = ref.current;
      if (currentScrollRef) {
        const { scrollWidth, scrollLeft, clientWidth } = currentScrollRef;
        setIsPreviousDisabled(scrollLeft === 0);
        setIsNextDisabled(
          Math.floor(scrollWidth - scrollLeft - clientWidth) <= 0
        );
        setShowPagination(scrollWidth > clientWidth);
        // Not sure how to test this part since it relies on a ref variable updating
        // istanbul ignore if
        if (scrollLeft !== scrollPosition.current) {
          if (isPaginatingTimeout.current) {
            clearTimeout(isPaginatingTimeout.current); // Clear any existing timeout to prevent multiple calls
          }
          isPaginatingTimeout.current = setTimeout(() => {
            const direction =
              scrollLeft > scrollPosition.current ? "right" : "left";
            onScroll?.({
              direction,
              ...getScrollDirectionIndices(ref, scrollPosition.current),
              interactionType: isButtonScroll.current
                ? "button click"
                : "swipe",
            });
            scrollPosition.current = scrollLeft;
            isButtonScroll.current = false;
          }, 100);
        }
      }
    };

    handleScroll();

    const currentScrollRef = ref.current;

    currentScrollRef?.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      currentScrollRef?.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [ref, onScroll]);

  return {
    isPreviousDisabled,
    isNextDisabled,
    showPagination,
    paginateLeft: () => {
      isButtonScroll.current = true;
      paginate("left", ref);
    },
    paginateRight: () => {
      isButtonScroll.current = true;
      paginate("right", ref);
    },
  };
}
