import { TextSection } from "src/components/DesignSystem/TextSection";

import { ITextSection2Dot5 } from "../../../../../@types/generated/contentful";

export default function TextSection2Dot5Saddle({
  content: {
    fields: { title, textAlignment, desktopTextAlignment, bodyRichText },
  },
}: {
  content: ITextSection2Dot5;
}) {
  return (
    <TextSection
      title={title}
      body={bodyRichText}
      align={textAlignment}
      desktopAlign={desktopTextAlignment}
    />
  );
}
