/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Heading, LinkContext } from "@components";
import { ErrorBoundary, Provider } from "@rollbar/react";
import { useTypedQuery } from "src/lib/hooks/useTypedQuery";

interface IClientLoggerProps {
  children: JSX.Element | JSX.Element[];
  rollbarConfig: {
    clientToken: string;
    environment: string;
  };
}

function FallbackUI({ error }: { error: Error | null }) {
  const { data: preview } = useTypedQuery(["inPreviewMode"]);
  const LinkComponent = React.useContext(LinkContext);
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="flex flex-col items-start p-4 break-all max-w-screen-twinXl full:p-0 full:break-normal">
        <Heading as="h1">Oops!</Heading>
        <p>
          {preview
            ? error?.message
            : "Something went wrong, we're working on it!"}
        </p>
        <LinkComponent variant="button-underline" href="/" className="mt-4">
          Back to Safety
        </LinkComponent>
      </div>
    </div>
  );
}

export function ClientLogger({ children, rollbarConfig }: IClientLoggerProps) {
  const config = {
    accessToken: rollbarConfig?.clientToken,
    enabled: !!rollbarConfig?.clientToken,
    environment: rollbarConfig?.environment,
    captureUncaught: true,
    captureUnhandledRejections: true,
  };

  return (
    <Provider config={config}>
      <ErrorBoundary fallbackUI={FallbackUI}>{children}</ErrorBoundary>
    </Provider>
  );
}
