import { Hero } from "src/components/DesignSystem/Hero";

import { IHero2Dot5 } from "../../../../../@types/generated/contentful";
import { Media } from "../../Media";
import { responsiveMediaToAsset } from "../util";

export default function Hero2Dot5Saddle({
  content: {
    fields: {
      baseAsset: rawBaseAsset,
      desktopAsset: rawDesktopAsset,
      eyebrows,
      header,
      subCopy,
      ctas,
      overlayColor,
      overlayOpacity,
      horizontalAlignment,
      verticalAlignment,
      link,
    },
  },
}: {
  content: IHero2Dot5;
}) {
  const mobileAsset = responsiveMediaToAsset(rawBaseAsset);
  const desktopAsset = rawDesktopAsset
    ? responsiveMediaToAsset(rawDesktopAsset)
    : mobileAsset;
  return (
    <>
      <Media lessThan="full">
        <Hero
          asset={mobileAsset}
          overlayColor={overlayColor}
          overlayOpacity={overlayOpacity}
          eyebrows={eyebrows}
          header={header}
          subcopy={subCopy}
          horizontalAlignment={horizontalAlignment}
          verticalAlignment={verticalAlignment}
          ctaList={ctas?.map((cta) => ({
            href: cta.fields.url,
            text: cta.fields.text,
          }))}
          href={link}
        />
      </Media>
      <Media greaterThanOrEqual="full">
        <Hero
          asset={desktopAsset}
          overlayColor={overlayColor}
          overlayOpacity={overlayOpacity}
          eyebrows={eyebrows}
          header={header}
          subcopy={subCopy}
          horizontalAlignment={horizontalAlignment}
          verticalAlignment={verticalAlignment}
          ctaList={ctas?.map((cta) => ({
            href: cta.fields.url,
            text: cta.fields.text,
          }))}
          href={link}
        />
      </Media>
    </>
  );
}
