const selectors = {
  DesktopNavigation: {
    container: "nav.desktop-navigation",
    level1Link: "nav.desktop-navigation--link-1",
    megaMenu: "nav.desktop-navigation--megamenu",
    searchButton: "nav.desktop-navigation--search-btn",
    searchPane: "nav.desktop-navigation--search-pane",
    cartBadge: "nav.desktop-navigation--cart-badge",
    mediaElement: "nav.desktop-navigation--media-element",
  },
  MobileNavigation: {
    sheetContainer: "nav.mobile-navigation--sheet",
    menuContainer: "nav.mobile-navigation--menu",
    menuButton: "nav.mobile-navigation--menu-btn",
    searchButton: "nav.mobile-navigation--search-btn",
    searchPane: "nav.mobile-navigation--search-pane",
    searchPaneCloseButton: "nav.mobile-navigation--search-pane--close-btn",
    cartBadge: "nav.mobile-navigation--cart-badge",
    mediaElement: "nav.mobile-navigation--media-element",
  },
  MenuStack: {
    slideOverMenu: (text?: string | null) =>
      `ul.menu-stack--slide-over-menu-${text}`,
  },
  responsiveNavContainer: "responsive-nav-container",
  navigationContainer: "navigation",
};

export default selectors;
