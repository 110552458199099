import React, { Children, PropsWithChildren, useRef } from "react";
import { Button, Heading, Icon, LoadingIndicator } from "@components";
import classNames from "classnames";
import { motion, useScroll, useTransform } from "framer-motion";

import { Media } from "../../lib/components";
import { useBreakpoints } from "../../lib/hooks/useBreakpoints";

import { selectors } from "./selectors";
import { useScrollHandler } from "./useScrollHandler";
import { getChildWidth } from "./utils";

interface ITitle {
  value: string;
  underline: boolean;
}

function Title({ value, underline }: ITitle) {
  return (
    <div>
      <Heading
        as="h2"
        variant="h4"
        className="text-[25px] leading-8 md:leading-eighth md:text-lg"
      >
        {value}
      </Heading>
      {underline && <div className="w-20 h-[3px] mt-4 bg-charcoal" />}
    </div>
  );
}

interface IPaginationButton {
  onClick: () => void;
  disabled?: boolean;
  chevronDirection: "left" | "right";
  variant: "base" | "small";
}

export function PaginationButton({
  onClick,
  disabled,
  chevronDirection,
  variant,
}: IPaginationButton) {
  return (
    <Button
      data-testid={selectors.pagination.button[chevronDirection]}
      variant="unstyled"
      disabled={disabled}
      aria-disabled={disabled}
      onClick={onClick}
      className={classNames(
        "flex items-center justify-center w-8 h-8 border rounded-full",
        "bg-flint-10 disabled:opacity-50 border-flint-20 hover:bg-flint-20 hover:border-flint-25",
        {
          "lg:w-12 lg:h-12": variant === "base",
        }
      )}
    >
      <Icon
        name={
          chevronDirection === "left"
            ? "chevron-left-rounded"
            : "chevron-right-rounded"
        }
        className="w-5 h-5"
      />
    </Button>
  );
}

interface CardCarouselProps {
  showProgressBar?: boolean;
  showPagination?: boolean;
  title?: ITitle;
  analyticsName: string;
  cardsToShow?: number;
  peekPercentage?: number;
  isLoading?: boolean;
  paginationVariant?: "base" | "small";
  location?: string;
}

export function CardCarousel({
  showProgressBar = false,
  showPagination = false,
  title,
  analyticsName,
  cardsToShow = 2,
  peekPercentage = 20,
  isLoading = false,
  location,
  children,
  paginationVariant = "base",
}: PropsWithChildren<CardCarouselProps>) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollXProgress } = useScroll({ container: scrollRef });
  const x = useTransform(scrollXProgress, [0, 1], ["0vw", "25vw"]); // Track = 50vw | Bar = 25vw. So 25vw will move the bar to the other end of the track
  const desktopX = useTransform(scrollXProgress, [0, 1], ["0%", "150%"]); // Track = 100% | Bar = 40%. So 125% of itself will move the bar to the other end of the track

  const { onPrevClick, onNextClick, isAtBeginningOfScroll, isAtEndOfScroll } =
    useScrollHandler({
      scrollRef,
      numberOfChildren: Children.count(children),
      carouselName: analyticsName,
      location,
    });

  const { fullVisible } = useBreakpoints();

  const childrenCount = Children.count(children);
  const isPeek = peekPercentage > 0;
  const isFullBleed = childrenCount > cardsToShow && isPeek;

  const shouldShowProgressBar =
    showProgressBar &&
    childrenCount !== cardsToShow &&
    !(isAtBeginningOfScroll && isAtEndOfScroll);

  return (
    <section
      className={classNames("pl-6 max-w-screen-king full:pl-8 king:pr-8", {
        "pr-6 full:pr-8": !isFullBleed,
      })}
    >
      {title && (
        <div
          className={classNames("flex items-center justify-between ", {
            "pr-6 full:pr-8 king:pr-0": isFullBleed,
          })}
        >
          <Title value={title.value} underline={title.underline} />
          {showPagination &&
            !(isAtBeginningOfScroll && isAtEndOfScroll) &&
            !isLoading && (
              <div className="flex justify-center space-x-2 lg:space-x-3">
                <PaginationButton
                  onClick={onPrevClick}
                  disabled={isAtBeginningOfScroll}
                  chevronDirection="left"
                  variant={paginationVariant}
                />
                <PaginationButton
                  onClick={onNextClick}
                  disabled={isAtEndOfScroll}
                  chevronDirection="right"
                  variant={paginationVariant}
                />
              </div>
            )}
        </div>
      )}
      {isLoading ? (
        <LoadingIndicator containerClassName="text-center mt-4" />
      ) : (
        <div
          className={classNames(
            "block whitespace-nowrap card-carousel-progress-bar card-carousel-hide-progress-bar",
            {
              "pt-6 lg:pt-8": title,
              "pb-8 lg:pb-12": showProgressBar,
            }
          )}
          ref={scrollRef}
        >
          {Children.map(children, (child, i) => (
            <div
              style={{
                width: getChildWidth({
                  numberOfChildrenToShow: cardsToShow,
                  peekPercentage,
                  isMobile: !fullVisible,
                }),
              }}
              className={classNames(
                "whitespace-normal inline-block align-top card-carousel-progress-bar-item",
                {
                  "mr-3 full:mr-4": i !== childrenCount - 1,
                  "mr-6 full:mr-8 king:mr-0":
                    i === childrenCount - 1 && isFullBleed,
                }
              )}
            >
              {child}
            </div>
          ))}
        </div>
      )}
      {/*
       * Mobile Progress Bar. Track = 50vw | Bar = 25vw
       * Desktop Progress Bar. Track = 100% | Bar = 40%
       */}
      {shouldShowProgressBar && (
        <>
          <Media lessThan="lg">
            <div className="flex items-center justify-center">
              {/* Scroll track is 50vw  */}
              <div className="bg-lightgray w-[50vw] h-0.5">
                <motion.div
                  className="h-0.5 bg-charcoal w-[25vw]"
                  style={{ x }}
                />
              </div>
            </div>
          </Media>
          <Media greaterThanOrEqual="lg">
            <div className="flex items-center justify-center">
              {/* Scroll track is 100%  */}
              <div className="bg-lightgray w-full h-0.5">
                <motion.div
                  className="h-0.5 bg-charcoal w-[40%]"
                  style={{ x: desktopX }}
                />
              </div>
            </div>
          </Media>
        </>
      )}
    </section>
  );
}
