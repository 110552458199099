import { useEffect, useRef, useState } from "react";

type UseSectionObserverProps = {
  sectionIds: string[];
  isLinkListVisible: boolean;
};

type ActiveSection = string[];

export default function useSectionObserver({
  sectionIds,
  isLinkListVisible,
}: UseSectionObserverProps): ActiveSection {
  const [activeSection, setActiveSection] = useState<ActiveSection>([]);
  const observersRef = useRef<IntersectionObserver>();

  useEffect(() => {
    // Function to handle intersection changes
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const includedValues: string[] = [];
      const removedValues: string[] = [];
      entries.forEach((entry) => {
        const threshold = 0.4;
        const isIntersecting =
          entry.intersectionRatio >= threshold ||
          entry.intersectionRect.height >
            threshold * (entry.rootBounds?.height ?? window.innerHeight);
        if (isIntersecting) {
          includedValues.push(entry.target.id);
        } else {
          removedValues.push(entry.target.id);
        }
      });

      setActiveSection((prev) => {
        const newActiveSections = Array.from(
          new Set([...prev, ...includedValues])
        );
        return newActiveSections.filter((id) => !removedValues.includes(id));
      });
    };

    observersRef.current = new IntersectionObserver(handleIntersection, {
      threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9], // Run this every 10% of the way
    });
    // Set up IntersectionObservers for each section
    sectionIds.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        observersRef.current?.observe(element);
      }
    });

    // Clean up observers when the component unmounts or sectionIds change
    return () => {
      observersRef.current?.disconnect();
    };
  }, [sectionIds, isLinkListVisible]);

  return activeSection;
}
