import { MouseEvent } from "react";
import { useRouter } from "next/router";

import subnavigationSelectors from "../../../components/SubNavigation/selectors";
import navigationSelectors from "../Navigation/selectors";

export default function useSmoothScrolling(href: string | undefined) {
  const router = useRouter();

  const handleScrollOrNavigate = (event: MouseEvent<HTMLAnchorElement>) => {
    if (!href || !href.includes("#")) return;

    const [destination, fragment] = href.split("#");
    if (destination && router.asPath !== destination) return;

    const targetElement = document.getElementById(fragment);
    if (!targetElement) return;

    event.preventDefault();

    const subNavigation = document.getElementById(
      subnavigationSelectors.subnavigationContainer
    );
    const navigation = document.getElementById(
      navigationSelectors.navigationContainer
    );
    const subnavMobile = document.getElementById(
      subnavigationSelectors.mobileJumpLinksContainer
    );
    if (subNavigation && navigation) {
      // Calculate the scroll distance accounting for the sub navigation
      const subnavBottom = subNavigation.getBoundingClientRect().bottom;
      // use target element to calculate the scroll distance
      const targetTop = targetElement.getBoundingClientRect().top;
      const navigationTop = navigation.getBoundingClientRect().top;
      const subnavMobileHeight =
        subnavMobile?.getBoundingClientRect().height ?? 0;

      window.scrollBy({
        top: targetTop - subnavBottom + navigationTop - subnavMobileHeight,
        behavior: "smooth",
      });
    } else {
      targetElement.scrollIntoView({ block: "start", behavior: "smooth" });
      setTimeout(() => {
        router.push(href);
      }, 500);
    }
  };

  return handleScrollOrNavigate;
}
