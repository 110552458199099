import { PropsWithChildren, useContext } from "react";

import { cn } from "../../../utils/tailwind-merge";
import { LinkContext } from "../../Link";

import { selectors } from "./selectors";

type CTAHref = { href: string; onClick?: () => void };
type CTAOnClick = { href?: string; onClick: () => void };

type CTAProps = {
  className?: string;
} & (CTAHref | CTAOnClick);

export function CTA({
  children,
  href,
  className,
  onClick,
  ...rest
}: PropsWithChildren<CTAProps>) {
  const SiteLink = useContext(LinkContext);
  return (
    <SiteLink
      href={href}
      onClick={onClick}
      data-testid={selectors.cta}
      className={cn(
        "text-cta leading-cta tracking-cta border-b-1 border-solid pb-1.5 whitespace-nowrap hover:opacity-70 w-fit",
        className
      )}
      {...rest}
    >
      {children}
    </SiteLink>
  );
}
