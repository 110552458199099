/* eslint-disable import/prefer-default-export */
import * as React from "react";
import ProductTileContext from "src/lib/context/ProductTileContext";
import { IProductTileProps } from "src/lib/services/elevar/interfaces";

import { ProductCard } from "../../../components/ProductCard";
import usePriceFormatter from "../../hooks/usePriceFormatter";
import {
  addHeapProductTileClicked,
  addHeapProductTileViewed,
} from "../../services/elevar/events";
import makeProductUrl from "../../util/makeProductUrl";
import { useAnalyticsHandler } from "../RenderSections/hooks/useAnalyticsHandler";
import { ProductCardType } from "../RenderSections/types";

export interface ProductCardSectionProps {
  product: ProductCardType["fields"];
  className?: string;
}

export function ProductCardSection({
  product: {
    slug,
    title,
    name,
    productId,
    sku,
    variantTitle,
    variantId,
    price,
    images: [defaultImage],
  },
  className,
}: ProductCardSectionProps) {
  const formatPrice = usePriceFormatter();

  // Force image to be square, as per UX
  const imageFile = defaultImage.fields.file.details?.image;
  const imageWidth =
    imageFile?.width !== undefined && imageFile?.height !== undefined
      ? Math.min(imageFile?.width, imageFile?.height)
      : undefined;

  const image = {
    width: imageWidth,
    height: imageWidth,
    url: defaultImage.fields.file.url,
    title: defaultImage.fields.title,
    description: defaultImage.fields.description,
  };

  const url = makeProductUrl(slug, variantId);
  const productTileInfo = React.useContext(ProductTileContext);

  const handleAnalytics = useAnalyticsHandler();
  const producTileEventProps: IProductTileProps = {
    productID: productId,
    productTitle: title,
    sku,
    variantID: variantId,
    variantTitle,
    productTileTitle: name,
    price,
    productListSectionTitle: productTileInfo?.productListSectionTitle,
    visibleOnPageload: productTileInfo?.visibleOnPageload,
    location: "Recs Widget - Curated",
    defaultImageURL: image.url,
    displayedImageURL: image.url,
    productTileSectionPosition:
      productTileInfo?.tilePosition !== undefined
        ? productTileInfo.tilePosition + 1
        : undefined,
  };
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    handleAnalytics(e);
    addHeapProductTileClicked(producTileEventProps);
  };
  const onViewed = () => {
    addHeapProductTileViewed(producTileEventProps);
  };

  return (
    <ProductCard
      url={url}
      title={title}
      price={formatPrice(price)}
      image={image}
      className={className}
      onClick={onClick}
      onViewed={onViewed}
    />
  );
}
