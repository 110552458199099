/* eslint-disable import/prefer-default-export */
import * as React from "react";
import classNames from "classnames";

import { selectors } from "./selector";

interface SpacerSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  /** The height of the section */
  size?: "64px" | "32px" | "0px" | string | undefined;
}

const height: Record<Required<SpacerSectionProps>["size"], string> = {
  "64px": "h-12 full:h-16",
  "32px": "h-6 full:h-8",
  "0px": "h-0",
};

/**
 * An empty section that can be used to create vertical space between other
 * sections.
 */
export function SpacerSection({
  size = "64px",
  className,
  ...props
}: SpacerSectionProps) {
  return (
    <div
      aria-hidden="true"
      data-testid={selectors.spacer}
      className={classNames(height[size] ?? height["64px"], className)}
      {...props}
    />
  );
}
