/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Button, Heading, Icon } from "@components";
import classNames from "classnames";
import useVisibility from "src/hooks/useVisibility";
import { useVerticalSpacing } from "src/lib/context/VerticalSpacingContext";
import useFeatureFlag from "src/lib/hooks/useFeatureFlag";
import { addHeapContentExpanded } from "src/lib/services/elevar/events";

import selectors from "./selectors";

interface ReadMoreSectionProps {
  title: string;
  children: React.ReactNode;
  expandButtonText: string;
  collapseButtonText: string;
  defaultOpen?: boolean;
}

/**
 * Create collapsible content sections, enabling readers to explore additional details
 * while maintaining a concise and organized presentation.
 */
export function ReadMoreSection({
  title,
  children,
  expandButtonText,
  collapseButtonText,
  defaultOpen,
}: ReadMoreSectionProps) {
  const { value } = useFeatureFlag("collection-page-appearance");
  const { hasVerticalSpacing } = useVerticalSpacing();
  const sectionName = "readMoreSection";
  const { isFullTextVisible, toggleFullText } = useVisibility(
    "FullText",
    defaultOpen,
    {
      onShow: () => addHeapContentExpanded(sectionName, expandButtonText, true),
      onHide: () =>
        addHeapContentExpanded(sectionName, collapseButtonText, false),
    }
  );

  const showNewAppearance = value === "modern";
  const visibilityClasses = !showNewAppearance ? "clamp clamp-1" : "hidden";

  return (
    <div
      className={classNames("px-4 mx-auto max-w-screen-2xl lg:px-8", {
        "text-center": showNewAppearance,
        "py-4 lg:py-8": hasVerticalSpacing,
      })}
    >
      <Heading
        as="h2"
        variant={!showNewAppearance ? "h5" : "h3"}
        className={!showNewAppearance ? "text-base" : "text-l"}
      >
        {title}
      </Heading>
      <div className="flex flex-col items-center text-2xs">
        <div
          className={classNames("w-full leading-normal", {
            [visibilityClasses]: !isFullTextVisible,
            "max-w-screen-sm": showNewAppearance,
          })}
        >
          {children}
        </div>
        <Button
          onClick={toggleFullText}
          variant="unstyled"
          className="flex items-center gap-2"
          data-testid={selectors.toggleButton}
        >
          {isFullTextVisible ? collapseButtonText : expandButtonText}
          <Icon
            name="chevron-down"
            className={classNames("transition", {
              "rotate-180": isFullTextVisible,
              hidden: showNewAppearance,
            })}
            size="inherit"
          />
        </Button>
      </div>
    </div>
  );
}
