import { Fragment, useContext } from "react";
import classNames from "classnames";
import { LinkContext } from "src/components/Link";
import { useBreakpoints } from "src/lib/hooks/useBreakpoints";

import { CTA } from "../CTA";
import { Heading } from "../Heading";
import { OverlayMedia, OverlayMediaProps } from "../OverlayMedia";
import { Text } from "../Text";

import { selectors } from "./selectors";

type PostCardProps = Omit<OverlayMediaProps, "children"> & {
  desktopAsset?: OverlayMediaProps["asset"];
  heading?: string;
  subcopy?: string;
  href?: string;
  cta?: {
    text: string;
    href: string;
  };
};

export function PostCard({
  asset: baseAsset,
  desktopAsset = baseAsset,
  verticalAlignment,
  horizontalAlignment,
  overlayOpacity,
  overlayColor,
  textColor,
  as,
  heading,
  subcopy,
  cta,
  href,
}: PostCardProps) {
  const { fullVisible } = useBreakpoints();
  const LinkElement = useContext(LinkContext);

  const Wrapper = href && !cta ? LinkElement : Fragment;

  const asset = fullVisible ? desktopAsset : baseAsset;

  return (
    <Wrapper href={href}>
      <OverlayMedia
        asset={asset}
        verticalAlignment={verticalAlignment}
        horizontalAlignment={horizontalAlignment}
        overlayOpacity={overlayOpacity}
        overlayColor={overlayColor}
        textColor={textColor}
        as={as}
      >
        <div
          className={classNames(
            "text-left flex flex-col items-start max-w-156",
            {
              "text-center items-center": horizontalAlignment === "center",
            }
          )}
        >
          {heading && (
            <Heading
              as="h3"
              variant={{ base: "h4", desktop: "h3" }}
              className="mb-0.5"
            >
              {heading}
            </Heading>
          )}
          {fullVisible && subcopy ? (
            <Heading as="h6" data-testid={selectors.subCopy.desktop}>
              {subcopy}
            </Heading>
          ) : (
            <Text data-testid={selectors.subCopy.mobile}>{subcopy}</Text>
          )}
          {cta && (
            <CTA href={cta.href} className="mt-4.5" data-testid={selectors.cta}>
              {cta.text}
            </CTA>
          )}
        </div>
      </OverlayMedia>
    </Wrapper>
  );
}
