import { createContext, useContext, useMemo } from "react";

export type GenericObjectInfo = {
  id: string;
  name: string;
  type?: string;
};
export const AnalyticsContext = createContext<{ tree: GenericObjectInfo[] }>({
  tree: [],
});

export function AnalyticsProvider({
  children,
  object,
}: {
  children: React.ReactNode;
  object: GenericObjectInfo;
}) {
  const { tree } = useContext(AnalyticsContext);
  const providedValue = useMemo(
    () => ({
      tree: [...tree, object],
    }),
    [object, tree]
  );

  return (
    <AnalyticsContext.Provider value={providedValue}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export const unknownObject = { id: "unknown id", name: "Unknown" };

export const useAnalytics = () => {
  const { tree } = useContext(AnalyticsContext);
  let currentObject: GenericObjectInfo = unknownObject;
  let parentObject: GenericObjectInfo = unknownObject;
  if (tree.length > 0) {
    currentObject = tree[tree.length - 1];
  }
  if (tree.length > 1) {
    parentObject = tree[tree.length - 2];
  }
  return { currentObject, parentObject };
};
