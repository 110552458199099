/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Icon } from "@components";
import classNames from "classnames";

import { cn } from "../../utils/tailwind-merge";
import {
  buttonClasses,
  darkToLightButtonClasses,
  darkToPineButtonClasses,
  lightToDarkButtonClasses,
  lightToGrayButtonClasses,
  pineToDarkButtonClasses,
  sageToWhiteButtonClasses,
  transparentToDarkButtonClasses,
  transparentToWhiteButtonClasses,
  underlineButtonClasses,
} from "../Button/Button";

const { createContext } = React;

type Variant =
  | "underline"
  | "button-dark-to-pine" // dark
  | "button-light-to-dark" // light
  | "button-light-to-gray" // gray
  | "button-dark-to-light"
  | "button-pine-to-dark"
  | "button-sage-to-white"
  | "button-transparent-to-white"
  | "button-transparent-to-dark"
  | "button-underline"
  | "link";

export interface LinkProps {
  /** The url the link will route to */
  href?: string;
  /** The title (hover message) of the link */
  title?: string;
  /** Classnames to pass through to the component */
  className?: string;
  /** Whether the link is disabled or not */
  disabled?: boolean;
  /** Whether the link is draggable or not */
  draggable?: boolean;
}
export interface ContextLinkProps extends LinkProps {
  /** The variant style to use for the link */
  variant?: Variant;
  /** A boolean to enable icon on hover */
  arrowIcon?: boolean;
  /** The target locale to use for the link */
  locale?: string;
  /** whether to keep the underline visible regardless of hover */
  forceUnderline?: boolean;
  /** onCLick handler */
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  /** whether to open the link in a new tab */
  target?: "_blank" | "_self" | "_parent" | "_top";
}

const linkClasses =
  "relative group cursor-pointer focus-visible:outline-offset-4 focus-visible:outline-pine focus-visible:outline underline-offset-2";

function getClasses(variant?: Variant, additionalClasses = "") {
  return classNames(
    {
      [linkClasses]: !variant?.includes("button"),
      [buttonClasses]: variant?.includes("button"),
      [darkToPineButtonClasses]: variant === "button-dark-to-pine",
      [lightToDarkButtonClasses]: variant === "button-light-to-dark",
      [lightToGrayButtonClasses]: variant === "button-light-to-gray",
      [darkToLightButtonClasses]: variant === "button-dark-to-light",
      [sageToWhiteButtonClasses]: variant === "button-sage-to-white",
      [transparentToWhiteButtonClasses]:
        variant === "button-transparent-to-white",
      [pineToDarkButtonClasses]: variant === "button-pine-to-dark",
      [underlineButtonClasses]: variant === "button-underline",
      [transparentToDarkButtonClasses]:
        variant === "button-transparent-to-dark",
    },
    additionalClasses
  );
}

/**
 * The `Link` component allows users to navigate to different pages within or outside of an application
 */

export const Link = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<ContextLinkProps>
>(
  (
    {
      children,
      variant,
      forceUnderline,
      className,
      draggable,
      arrowIcon,
      ...props
    },
    ref
  ) => (
    <a
      {...props}
      ref={ref}
      className={cn(getClasses(variant), className)}
      draggable={draggable}
    >
      {children}
      {variant === "underline" && (
        <span
          aria-hidden
          className={classNames(
            "absolute left-0 w-full transition duration-300 origin-left scale-x-0 border-b pointer-events-none -bottom-1",
            forceUnderline
              ? "scale-x-100"
              : "group-hover:scale-x-100 group-focus-visible:scale-x-100"
          )}
          data-testid="link-underline-animated"
        />
      )}
      {arrowIcon && (
        <Icon
          name="arrow-right"
          className="absolute top-0 bottom-0 right-0 hidden h-2 pr-6 m-auto arrow group-hover:block"
        />
      )}
    </a>
  )
);

Link.displayName = "Link";

export const LinkContext =
  createContext<React.ElementType<ContextLinkProps>>(Link);
