import { PostCard } from "src/components/DesignSystem/PostCard";

import { IPostCard } from "../../../../../../../@types/generated/contentful";
import { responsiveMediaToAsset } from "../../../util";

export default function PostCardSaddle({ content }: { content: IPostCard }) {
  const data = content as IPostCard;
  const {
    fields: {
      asset,
      desktopAsset,
      overlayColor,
      overlayOpacity,
      textColor,
      header,
      subCopy,
      cta,
      verticalAlignment,
      horizontalAlignment,
      link,
    },
  } = data;

  return (
    <PostCard
      asset={responsiveMediaToAsset(asset)}
      desktopAsset={
        desktopAsset ? responsiveMediaToAsset(desktopAsset) : undefined
      }
      verticalAlignment={verticalAlignment}
      horizontalAlignment={horizontalAlignment}
      overlayOpacity={overlayOpacity}
      overlayColor={overlayColor}
      textColor={textColor}
      heading={header}
      subcopy={subCopy}
      href={link}
      cta={
        cta
          ? {
              text: cta.fields.text,
              href: cta.fields.url,
            }
          : undefined
      }
    />
  );
}
