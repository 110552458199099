/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import { createRef, useEffect, useState } from "react";
import { LinkContext, Overlay } from "@components";
import classNames from "classnames";
import { useRouter } from "next/router";
import useFeatureFlag from "src/lib/hooks/useFeatureFlag";

import { makeColorStyles } from "../../../utils/makeColorStyles";
import { Media, NavLink } from "..";

import DesktopNavigation from "./components/DesktopNavigation";
import MobileNavigation from "./components/MobileNavigation";
import selectors from "./selectors";
import { DesktopNavigationProps, MobileNavigationProps } from "./types";

export const scrollThreshold = 40;

export type ResponsiveNavigationProps = {
  desktopNav: DesktopNavigationProps;
  mobileNav: MobileNavigationProps;
};
type Props = React.PropsWithChildren<ResponsiveNavigationProps>;

export function ResponsiveNavigation({
  desktopNav: {
    headerColor,
    headerOpacity,
    headerTextColor,
    transparentNav: rawTransparentNav,
    ...navProps
  },
  mobileNav,
}: ResponsiveNavigationProps) {
  const { asPath } = useRouter();
  const { value } = useFeatureFlag("collection-page-appearance");

  // TODO: remove once PLP redesign experiment is concluded
  const bypassTransparentNav =
    asPath.includes("collections") && value === "modern";

  const transparentNav = bypassTransparentNav ? false : rawTransparentNav;

  const [showFixedNav, setShowFixedNav] = useState(transparentNav);
  const containerRef = createRef<HTMLDivElement>();
  const { color, backgroundColor } = makeColorStyles(
    headerColor || "transparent",
    headerTextColor || "white"
  );

  useEffect(() => {
    const updateFixedState = () => {
      setShowFixedNav(transparentNav && window.scrollY < scrollThreshold);
    };

    // immediately run the handler to update the fixed state
    updateFixedState();

    // if not using transparent nav, stop here
    if (!transparentNav) return () => {};

    // otherwise, listen for scroll events & cleanup
    window.addEventListener("scroll", updateFixedState);
    return () => {
      window.removeEventListener("scroll", updateFixedState);
    };
  }, [transparentNav]);

  const mediaClassNames = classNames("transition-all", {
    "absolute duration-300 hover:duration-150 inset-x-0 top-0 lg:hover:bg-white lg:hover:text-charcoal hoverable:hover:bg-white hoverable:hover:text-charcoal":
      transparentNav,
    "bg-white text-charcoal": !transparentNav || !showFixedNav,
    relative: !transparentNav,
  });

  const containerStyle = { color };
  const overlayContent = (
    <Overlay
      overlayColorBottom={backgroundColor}
      overlayColorMiddle={backgroundColor}
      overlayColorTop={backgroundColor}
      overlayOpacity={showFixedNav ? headerOpacity : "0"}
    />
  );

  return (
    <div
      ref={containerRef}
      className="sticky top-0 z-header"
      id={selectors.navigationContainer}
      style={containerStyle}
      data-fixed={showFixedNav}
      data-testid={selectors.responsiveNavContainer}
      data-transparent={transparentNav}
    >
      <Media greaterThanOrEqual="lg" className={mediaClassNames}>
        {overlayContent}
        <DesktopNavigation {...navProps} />
      </Media>
      <Media lessThan="lg" className={mediaClassNames}>
        {overlayContent}
        <MobileNavigation {...mobileNav} fromDesktop={navProps} />
      </Media>
    </div>
  );
}

/**
 * Component to power site navigation based on configuration in Contentful
 */
export function Navigation({ children, ...navProps }: Props) {
  return (
    <>
      {children}
      <LinkContext.Provider value={NavLink}>
        <ResponsiveNavigation {...navProps} />
      </LinkContext.Provider>
    </>
  );
}
