import {
  ComponentProps,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  CardCarousel,
  Heading,
  ImageContext,
  LinkContext,
  SubNavigation,
} from "@components";
import { useIntersectionObserver } from "src/hooks/useIntersectionObserver";
import useVisibility from "src/hooks/useVisibility";
import { addHeapProductListNavigationClicked } from "src/lib/services/elevar/events";
import { getIsInitiallyVisible } from "src/lib/util/getIsInitiallyVisible";
import { useDebouncedCallback } from "use-debounce";

import { Media } from "../Media";
import { ImageData } from "../util";

import useSectionObserver from "./hooks/useSectionObserver";
import selectors from "./selectors";

export interface CollectionAssemblyItem {
  title: string;
  image: ImageData;
  description: string;
  anchor: string;
}

interface CarouselConfig {
  showProgressBar?: boolean;
  showPagination?: boolean;
  mobileCards: number;
  tabletCards: number;
  desktopCards: number;
  mobilePeek?: number;
  tabletPeek?: number;
  desktopPeek?: number;
}

interface CollectionAssemblyProps {
  name: string;
  title: string;
  items: CollectionAssemblyItem[];
  carouselConfig: CarouselConfig;
  ctaText: string;
}

const makeFragmentLink = (anchor: string) => `#${anchor}`;

function CollectionCard({
  title,
  image,
  description,
  ctaText,
  anchor,
  onImageClicked,
  onCtaClicked,
}: CollectionAssemblyItem &
  Pick<CollectionAssemblyProps, "ctaText"> & {
    onImageClicked: () => void;
    onCtaClicked: () => void;
  }) {
  const ImageElement = useContext(ImageContext);
  const LinkElement = useContext(LinkContext);

  return (
    <div data-testid={selectors.carousel.card} className="flex flex-col gap-4">
      <LinkElement href={makeFragmentLink(anchor)} onClick={onImageClicked}>
        <ImageElement
          src={image.src}
          alt={image.alt}
          height={image.height}
          width={image.width}
        />
      </LinkElement>
      <div className="flex flex-col items-start w-full gap-0.5">
        <Heading as="h3" className="leading-eighth text-l">
          {title}
        </Heading>
        <p className="whitespace-normal leading-small">{description}</p>
        <LinkElement
          className="mb-0 uppercase"
          variant="button-underline"
          href={makeFragmentLink(anchor)}
          onClick={onCtaClicked}
        >
          {ctaText}
        </LinkElement>
      </div>
    </div>
  );
}

const Carousels = memo(
  ({
    name,
    items,
    carouselConfig: config,
    ctaText,
  }: Pick<
    CollectionAssemblyProps,
    "name" | "items" | "carouselConfig" | "ctaText"
  >) => {
    const sharedConfig: ComponentProps<typeof CardCarousel> = {
      analyticsName: name,
      showPagination: config.showPagination,
      showProgressBar: config.showProgressBar,
    };

    const analyticsProps = useCallback(
      (
        elementName: string,
        position: number,
        visibleOnPageload: "yes" | "no"
      ) => ({
        onImageClicked: () =>
          addHeapProductListNavigationClicked({
            productListNavElementType: "carousel_card_image",
            productListNavElementName: elementName,
            productListNavElementPosition: position,
            visibleOnPageload,
          }),
        onCtaClicked: () =>
          addHeapProductListNavigationClicked({
            productListNavElementType: "carousel_card_cta",
            productListNavElementName: elementName,
            productListNavElementPosition: position,
            visibleOnPageload,
          }),
      }),
      []
    );

    return (
      <>
        <Media lessThan="twinXl">
          <CardCarousel
            {...sharedConfig}
            cardsToShow={config.mobileCards}
            peekPercentage={config.mobilePeek}
          >
            {items.map((item, index) => (
              <CollectionCard
                key={item.anchor}
                {...item}
                ctaText={ctaText}
                {...analyticsProps(
                  item.title,
                  index + 1,
                  getIsInitiallyVisible(
                    config.mobileCards,
                    index,
                    config.mobilePeek
                  )
                )}
              />
            ))}
          </CardCarousel>
        </Media>
        <Media lessThan="full" greaterThanOrEqual="twinXl">
          <CardCarousel
            {...sharedConfig}
            cardsToShow={config.tabletCards}
            peekPercentage={config.tabletPeek}
          >
            {items.map((item, index) => (
              <CollectionCard
                key={item.anchor}
                {...item}
                ctaText={ctaText}
                {...analyticsProps(
                  item.title,
                  index + 1,
                  getIsInitiallyVisible(
                    config.tabletCards,
                    index,
                    config.tabletPeek
                  )
                )}
              />
            ))}
          </CardCarousel>
        </Media>
        <Media greaterThanOrEqual="full">
          <CardCarousel
            {...sharedConfig}
            cardsToShow={config.desktopCards}
            peekPercentage={config.desktopPeek}
          >
            {items.map((item, index) => (
              <CollectionCard
                key={item.anchor}
                {...item}
                ctaText={ctaText}
                {...analyticsProps(
                  item.title,
                  index + 1,
                  getIsInitiallyVisible(
                    config.desktopCards,
                    index,
                    config.desktopPeek
                  )
                )}
              />
            ))}
          </CardCarousel>
        </Media>
      </>
    );
  }
);
Carousels.displayName = "Carousels";

/**
 * Group of collection sections
 */
export default function CollectionAssembly({
  name,
  title,
  items,
  carouselConfig,
  ctaText,
  children,
}: PropsWithChildren<CollectionAssemblyProps>) {
  const sectionIds = useMemo(() => items.map((item) => item.anchor), [items]);
  const { isLinkListVisible, showLinkList, hideLinkList } = useVisibility(
    "LinkList",
    false
  );
  const activeSectionId = useSectionObserver({
    sectionIds,
    isLinkListVisible,
  });

  const debouncedHideLinkList = useDebouncedCallback(hideLinkList, 10);
  const debouncedShowLinkList = useDebouncedCallback(showLinkList, 10);

  const linkVisibilityRef = useIntersectionObserver(
    useCallback(
      (entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          debouncedHideLinkList();
        } else {
          debouncedShowLinkList();
        }
      },
      [debouncedHideLinkList, debouncedShowLinkList]
    )
  );

  const jumpLinks = items.map((item) => ({
    id: item.anchor,
    text: item.title,
    href: makeFragmentLink(item.anchor),
  }));

  return (
    <section className="w-full isolate" data-testid={selectors.container}>
      <SubNavigation
        title={title}
        className="sticky z-10 top-[88px] full:top-[72px] border-b-[0.5px] border-slate/25"
        showJumpLinks={isLinkListVisible}
        jumpLinks={jumpLinks}
        activeLinkId={activeSectionId[0]}
      />
      <div
        className="relative mx-auto mb-6 max-w-screen-king full:mb-16"
        data-testid={selectors.carousel}
      >
        {/* Here to offset when the jumplinks become visible */}
        <div
          className="absolute inset-x-0 top-0 opacity-0 pointer-events-none h-2/5 full:h-3/5"
          ref={linkVisibilityRef}
        />
        <Carousels
          name={name}
          items={items}
          carouselConfig={carouselConfig}
          ctaText={ctaText}
        />
      </div>

      {/* Child sections */}
      {children}
    </section>
  );
}
