/* eslint-disable @next/next/no-img-element */
/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { ImageContext, Video } from "@components";
import classNames from "classnames";

import { AnimateVisible } from "../../../components/AnimateVisible";
import { Color, makeColorStyles } from "../../../utils/makeColorStyles";
import { TextOverlayMedia, TextOverlayMediaProps } from "../TextOverlayMedia";

interface MediaProps {
  /** Title of media */
  title: string;

  /** Media source */
  url: string;

  /** Media width */
  width: number;

  /** Media height */
  height: number;

  /** Media mime type */
  type: string;
}

interface VideoConfigProps {
  controls?: boolean;
  loop?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  playsInline?: boolean;
}

export interface TextMediaProps {
  /** Desktop orientation */
  desktopLayoutStyle: "Image on right" | "Image on left";

  /** Mobile orientation */
  mobileLayoutStyle: "Image on top" | "Image on bottom" | "Hide image";

  /** Media content */
  media?: MediaProps;

  /** Content ratio */
  contentRatio?: "30/70" | "40/60" | "50/50" | "60/40" | "70/30" | "80/20";

  /** Vertical alignment */
  alignTop?: boolean;

  /** Background color of the section */
  backgroundColor?: Color;

  /** Whether to fade in the image when it becomes visible on screen */
  fadeIn?: boolean;

  /** Additional video configurations */
  videoConfig?: VideoConfigProps;

  /** Whether to overlay the content on the media */
  useOverlayLayout?: boolean;

  textOverlayMedia?: TextOverlayMediaProps | null;
}

export interface ImageVideoMediaProps {
  media?: MediaProps;
  textOverlayMedia?: TextOverlayMediaProps | null;
  videoConfig?: VideoConfigProps;
  backgroundColor?: Color;
}

/**
 TextMedia component that displays an image with text beside it.
 */

export function ImageVideoMedia({
  media,
  textOverlayMedia,
  videoConfig,
  backgroundColor,
}: ImageVideoMediaProps) {
  const ImageComponent = React.useContext(ImageContext);
  const isVideo = media ? media.type.startsWith("video") : false;
  const tomObject = textOverlayMedia
    ? {
        name: textOverlayMedia.name,
        responsiveMedia: textOverlayMedia.responsiveMedia,
        fadeIn: textOverlayMedia.fadeIn,
        overlayColorTop: textOverlayMedia.overlayColorTop || backgroundColor,
        overlayColorMiddle:
          textOverlayMedia.overlayColorMiddle || backgroundColor,
        overlayColorBottom:
          textOverlayMedia.overlayColorBottom || backgroundColor,
        overlayOpacity: textOverlayMedia.overlayOpacity,
        videoConfig: textOverlayMedia.videoConfig || videoConfig,
        link: textOverlayMedia.link,
        locationalRichTextSections: textOverlayMedia.locationalRichTextSections,
      }
    : undefined;

  if (textOverlayMedia) {
    return (
      <TextOverlayMedia
        // eslint-disable-next-line react/jsx-props-no-spreading
        lrtClassName="px-4 py-5 full:px-8 full:py-7"
        {...tomObject}
        keepDimensions={{ mobile: true, desktop: true }}
      />
    );
  }
  if (isVideo && media) {
    return (
      <Video
        sources={[{ src: media.url, type: media.type }]}
        controls={videoConfig?.controls}
        autoPlay={videoConfig?.autoPlay}
        muted={videoConfig?.muted}
        playsInline={videoConfig?.playsInline}
        loop={videoConfig?.loop}
      />
    );
  }
  if (media) {
    return (
      <ImageComponent
        alt={media?.title}
        src={media?.url}
        width={media?.width}
        height={media?.height}
      />
    );
  }
  return null;
}

export function TextMedia({
  children,
  media,
  mobileLayoutStyle,
  desktopLayoutStyle,
  contentRatio,
  backgroundColor,
  videoConfig,
  fadeIn,
  alignTop = true,
  useOverlayLayout,
  textOverlayMedia,
}: React.PropsWithChildren<TextMediaProps>) {
  const backgroundColorStyles = makeColorStyles(backgroundColor || "white");
  const hasBackground = !!backgroundColor;

  const imageOnRight = desktopLayoutStyle === "Image on right";
  const imageOnLeft = desktopLayoutStyle === "Image on left";
  const imageOnTop = mobileLayoutStyle === "Image on top";
  const imageOnBottom = mobileLayoutStyle === "Image on bottom";
  const imageHidden = mobileLayoutStyle === "Hide image";

  const mainContainerClasses = {
    "lg:flex-row-reverse": imageOnRight,
    "lg:flex-row": imageOnLeft,
    "flex-col": imageOnTop || imageHidden,
    "flex-col-reverse": imageOnBottom,
    "lg:items-center": !alignTop,
    "items-center py-12 lg:py-16": hasBackground,
  };

  const imageContainerClasses = {
    hidden: mobileLayoutStyle === "Hide image",
    "md:basis-1/3": contentRatio === "30/70",
    "md:basis-2/5": contentRatio === "40/60",
    "md:basis-1/2": contentRatio === "50/50",
    "md:basis-3/5": contentRatio === "60/40",
    "md:basis-2/3": contentRatio === "70/30",
    "md:basis-4/5": contentRatio === "80/20",
  };

  const textContainerClasses = {
    "md:basis-1/5": contentRatio === "80/20",
    "md:basis-1/3": contentRatio === "70/30",
    "md:basis-2/5": contentRatio === "60/40",
    "md:basis-1/2": contentRatio === "50/50",
    "md:basis-3/5": contentRatio === "40/60",
    "md:basis-2/3": contentRatio === "30/70",
  };

  return (
    <div
      className={classNames("relative px-6", {
        "max-w-screen-king mx-auto lg:px-8": !useOverlayLayout,
        "lg:px-0": useOverlayLayout,
      })}
    >
      {hasBackground && (
        <div
          style={{ ...backgroundColorStyles }}
          className={classNames(
            "absolute top-0 bottom-0 left-0 lg:left-8 2xl:left-0 right-0 lg:right-1/4"
          )}
        />
      )}

      <div
        className={classNames(
          "flex gap-5 queen:gap-8 relative isolate z-0",
          mainContainerClasses,
          {
            "md:justify-center": contentRatio && !hasBackground,
          }
        )}
        data-testid="textmedia-image-container"
      >
        {/* Media */}
        <AnimateVisible
          fadeIn={fadeIn}
          className={classNames(
            "md:block relative w-full",
            hasBackground && "md:max-w-[750px]",
            { hidden: imageHidden },
            !useOverlayLayout && imageContainerClasses
          )}
        >
          <ImageVideoMedia
            textOverlayMedia={textOverlayMedia}
            media={media}
            videoConfig={videoConfig}
            backgroundColor={backgroundColor}
          />
        </AnimateVisible>

        {/* Text Content */}
        <div
          className={classNames(
            "text-charcoal",
            useOverlayLayout
              ? [
                  "lg:absolute inset-0 lg:grid lg:place-items-center lg:bg-black/50 lg:text-white",
                ]
              : [
                  "full:py-6",
                  textContainerClasses,
                  {
                    "max-w-sm lg:max-w-none": hasBackground,
                  },
                ]
          )}
        >
          {useOverlayLayout ? (
            <div className="max-w-xl">{children}</div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
}
