const selectors = {
  cardContent: "card-content",
  cardTitle: "card-title",
  cardBodyText: "card-body-text",
  cardBodyTextToggle: "card-body-text-toggle",
  cardFooter: "card-footer",
  cardImageContainer: "card-image-container",
  hoverImageWrapper: "hover-image-wrapper",
};

export default selectors;
