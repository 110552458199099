import { PropsWithChildren } from "react";

import { cn } from "../../../utils/tailwind-merge";

type TagOptions = "p" | "span" | "div";
type VariantOptions = "body" | "cta";

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  as?: TagOptions;
  variant?: VariantOptions;
}

export function Text({
  children,
  as = "p",
  variant = "body",
  className,
  ...rest
}: PropsWithChildren<TextProps>) {
  const ResolvedTag = as;
  return (
    <ResolvedTag
      className={cn(
        "font-sans",
        {
          "text-body leading-body tracking-body": variant === "body",
          "text-cta leading-cta tracking-cta": variant === "cta",
        },
        className
      )}
      {...rest}
    >
      {children}
    </ResolvedTag>
  );
}
