import { useContext, useEffect, useRef } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import {
  addHeapBackToTopClicked,
  addHeapProductListNavigationClicked,
  addHeapProductListNavigationInteracted,
} from "src/lib/services/elevar/events";
import { cn } from "src/utils/tailwind-merge";

import { useBreakpoints } from "../../lib/hooks/useBreakpoints";
import { Button } from "../Button";
import { Heading } from "../Heading";
import { LinkContext } from "../Link";
import { PaginationButtonPair } from "../PaginationButton/PaginationButton";

import { useScrollHandler } from "./hooks/useScrollHandler";
import selectors from "./selectors";

interface JumpLinkProps {
  text: string;
  href: string;
  isActive?: boolean;
  onClick?: () => void;
}

function JumpLink({ text, href, isActive, onClick }: JumpLinkProps) {
  const Link = useContext(LinkContext);
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (isActive) {
      const currentRef = ref.current;
      // istanbul ignore else -- my attempts to mock useRef are unfruitful, giving up for now
      if (currentRef) {
        const { parentElement } = currentRef;
        // istanbul ignore else -- same reasons as above
        if (parentElement) {
          const parentRect = parentElement.getBoundingClientRect();
          const currentRect = currentRef.getBoundingClientRect();
          const offset = currentRect.left - parentRect.left;

          parentElement.scrollTo({
            left: parentElement.scrollLeft + offset,
            behavior: "smooth",
          });
        }
      }
    }
  }, [isActive]);

  return (
    <Link
      ref={ref}
      key={text}
      href={href}
      className={classNames(
        "inline-block mb-1 scroll-bar-snap-item whitespace-nowrap hover:opacity-70 border-b border-b-transparent",
        { "border-b-charcoal": isActive }
      )}
      variant="link"
      id={href}
      onClick={onClick}
    >
      {text}
    </Link>
  );
}

interface JumpLinkListProps {
  jumpLinks: JumpLinkProps[];
}

function JumpLinkList({ jumpLinks }: JumpLinkListProps) {
  const ref = useRef<HTMLDivElement>(null);

  const {
    isPreviousDisabled,
    isNextDisabled,
    showPagination,
    paginateLeft,
    paginateRight,
  } = useScrollHandler(
    ref,
    // istanbul ignore next -- not sure how to test this
    ({ direction, fromIndex, toIndex, interactionType }) => {
      addHeapProductListNavigationInteracted({
        direction,
        interactionType,
        fromIndex,
        toIndex,
        fromName:
          fromIndex !== undefined ? jumpLinks[fromIndex]?.text : undefined,
        toName: toIndex !== undefined ? jumpLinks[toIndex]?.text : undefined,
      });
    }
  );

  return (
    <>
      <motion.div
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        exit={{ y: -20 }}
        transition={{ ease: "linear", duration: 0.15 }}
        ref={ref}
        className="flex items-center w-full max-w-full mr-3 space-x-6 overflow-x-scroll full:space-x-8 king:space-x-14 whitespace-nowrap no-scrollbar scroll-bar-snap-x full:ml-14 full:mr-3 full:h-9"
        data-testid={selectors.jumpLinksContainer}
      >
        {jumpLinks.map(({ text, href, isActive, onClick }) => (
          <JumpLink
            key={text}
            text={text}
            href={href}
            isActive={isActive}
            onClick={onClick}
          />
        ))}
      </motion.div>
      <div
        className={classNames({
          hidden: !showPagination,
          "full:h-9 full:flex full:pr-6 items-center": showPagination,
        })}
      >
        <PaginationButtonPair
          onPrevious={paginateLeft}
          onNext={paginateRight}
          isPreviousDisabled={isPreviousDisabled}
          isNextDisabled={isNextDisabled}
        />
      </div>
    </>
  );
}

type JumpLinkWithID = JumpLinkProps & { id: string };

interface SubNavigationProps {
  title: string;
  jumpLinks?: JumpLinkWithID[];
  showJumpLinks?: boolean;
  activeLinkId?: string;
  className?: string;
}

export function SubNavigation({
  title,
  jumpLinks = [],
  showJumpLinks = false,
  activeLinkId,
  className,
}: SubNavigationProps) {
  const { fullVisible } = useBreakpoints();
  const isMobile = !fullVisible;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    addHeapBackToTopClicked({ name: title, location: "PLP" });
  };

  const jumpLinksWithActive = jumpLinks.map((jumpLink, index) => ({
    ...jumpLink,
    isActive: activeLinkId === jumpLink.id,
    onClick: () =>
      addHeapProductListNavigationClicked({
        productListNavElementType: "carousel_menu",
        productListNavElementName: jumpLink.text,
        productListNavElementPosition: index + 1,
        visibleOnPageload: "no",
      }),
  }));

  return (
    <div
      className={cn("bg-white", className)}
      id={selectors.subnavigationContainer}
    >
      <div className="relative px-6 pb-6 mx-auto king:max-w-screen-king full:px-8 full:py-6">
        <div className="relative z-10 flex flex-col bg-white">
          <div className="flex justify-between">
            <Heading
              as="h1"
              variant="h4"
              className="text-[25px] leading-7 full:leading-eighth full:text-lg flex-shrink-0"
            >
              {title}
            </Heading>

            <AnimatePresence>
              {!isMobile && showJumpLinks && (
                <JumpLinkList jumpLinks={jumpLinksWithActive} />
              )}
            </AnimatePresence>

            <div className="flex items-center h-7 full:h-9">
              <Button
                variant="underline"
                className={cn(
                  "uppercase whitespace-nowrap text-2xs leading-[13px] mb-0 h-fit",
                  "transition duration-300",
                  { "opacity-0 height-0 pointer-events-none": !showJumpLinks }
                )}
                onClick={scrollToTop}
                data-testid={selectors.scrollToTopButton}
              >
                {/* TODO: i18n */}
                Back to Top
              </Button>
            </div>
          </div>
          <div className="w-20 border-charcoal border-b-[3px] mt-4" />
        </div>
        <div
          id={selectors.mobileJumpLinksContainer}
          className={classNames(
            "absolute bottom-0 left-0 right-0 z-0 flex max-w-full px-6 pb-6 bg-white border-b border-slate/25 full:hidden transition duration-300",
            {
              "opacity-0 pointer-events-none": !isMobile || !showJumpLinks,
              "translate-y-full": showJumpLinks,
            }
          )}
          data-testid={selectors.mobileJumpLinksContainer}
        >
          <JumpLinkList jumpLinks={jumpLinksWithActive} />
        </div>
      </div>
    </div>
  );
}
