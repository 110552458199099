import React from "react";

export const useToggleVideo = (
  videoRef: React.RefObject<HTMLVideoElement>,
  autoPlay?: boolean
) => {
  const [isPlaying, setIsPlaying] = React.useState(autoPlay ?? true);
  const handleTogglePlay = () => {
    const videoElement = videoRef.current;
    // Always toggle the state var
    if (videoElement) {
      setIsPlaying((currentlyIsPlaying) => {
        if (currentlyIsPlaying) {
          videoElement.dataset.playing = "false";
          videoElement.pause();
        } else {
          videoElement.play().then(() => {
            videoElement.dataset.playing = "true";
          });
        }
        return !currentlyIsPlaying;
      });
    }
  };
  return { isPlaying, handleTogglePlay };
};
