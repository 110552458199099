import { Card } from "src/components/DesignSystem/Card";

import { ICard2Dot5 } from "../../../../../../../@types/generated/contentful";
import { responsiveMediaToAsset } from "../../../util";

export default function CardSaddle({ content }: { content: ICard2Dot5 }) {
  const {
    fields: { asset, eyebrows, subHeading, paragraph, link, cta },
  } = content as ICard2Dot5;
  return (
    <Card
      asset={responsiveMediaToAsset(asset)}
      eyebrows={eyebrows}
      subheading={subHeading}
      paragraph={paragraph}
      href={link}
      cta={cta ? { text: cta.fields.text, href: cta.fields.url } : undefined}
    />
  );
}
