import { useContext } from "react";
import { ImageContext } from "src/components/Image";
import { Video } from "src/components/Video";

import { AssetType } from "./types";

export function Asset({ asset }: { asset: AssetType }) {
  const ImageElement = useContext(ImageContext);

  return asset.type === "image" ? (
    <ImageElement
      src={asset.src}
      alt={asset.alt}
      height={asset.height}
      width={asset.width}
    />
  ) : (
    <div
      style={{
        aspectRatio: `${asset.width} / ${asset.height}`,
      }}
    >
      <Video
        sources={asset.sources}
        controls={asset?.controls}
        autoPlay={asset.autoPlay || true}
        muted={asset.muted}
        playsInline={asset.playsInline}
        loop={asset.loop}
        poster={asset.posterUrl}
        className="object-cover w-full h-full"
      />
    </div>
  );
}
