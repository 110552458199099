import { Text } from "src/components/DesignSystem/Text/Text";

export function Eyebrows({
  eyebrows,
  className,
}: {
  eyebrows: string | string[];
  className?: string;
}) {
  let text: string | undefined;
  const textArray: (string | JSX.Element)[] = [];

  if (typeof eyebrows === "string") {
    text = eyebrows;
  } else {
    eyebrows.forEach((item, i) => {
      textArray.push(item);
      if (i !== eyebrows.length - 1) {
        textArray.push(<>&nbsp; | &nbsp;</>);
      }
    });
  }

  return (
    <Text variant="cta" className={className}>
      {text ?? textArray}
    </Text>
  );
}
