/*
 * DESCRIPTION:
 * This a generic component that is used to display a media asset with some something over layed on top of it.
 */

import { PropsWithChildren } from "react";
import classNames from "classnames";
import { Overlay } from "src/components/Overlay";
import { makeColorStyles } from "src/utils/makeColorStyles";

import { Asset } from "../Asset/Asset";
import { AssetType } from "../Asset/types";

type OverlayProps = {
  overlayOpacity?: string;
  overlayColor?: string;
};

export type OverlayMediaProps = {
  asset: AssetType;
  verticalAlignment?: "center" | "bottom";
  horizontalAlignment?: "left" | "center";
  textColor?: string;
  as?: React.ElementType;
} & OverlayProps;

export function OverlayMedia({
  verticalAlignment = "bottom",
  horizontalAlignment = "left",
  asset,
  overlayOpacity,
  overlayColor,
  textColor = "white",
  as,
  children,
}: PropsWithChildren<OverlayMediaProps>) {
  const Tag = as ?? "div";

  return (
    <Tag className="relative grid bg-flint-10">
      <Asset asset={asset} />
      <div className="box-border absolute top-0 bottom-0 left-0 right-0 z-10 w-full h-full p-6 full:p-8">
        <div
          className={classNames("flex h-full", {
            "items-center": verticalAlignment === "center",
            "items-end": verticalAlignment === "bottom",
            "justify-start": horizontalAlignment === "left",
            "justify-center": horizontalAlignment === "center",
          })}
          style={makeColorStyles("", textColor)}
        >
          {children}
        </div>
      </div>
      <Overlay
        overlayOpacity={overlayOpacity}
        overlayColorTop={overlayColor}
        overlayColorMiddle={overlayColor}
        overlayColorBottom={overlayColor}
      />
    </Tag>
  );
}
