import { Asset } from "contentful";
import { AssetType } from "src/components/DesignSystem/Asset/types";
import { isShopifyVideo } from "src/lib/util/shopifyVideo";

import {
  IResponsiveAsset,
  ITextOverlayMedia,
} from "../../../../@types/generated/contentful";
import { ImageData } from "../util";
import { TextOverlayMediaProps } from "..";

import { SectionType } from "./types";

const textMediaProps = (
  textOverlayMedia: ITextOverlayMedia
): TextOverlayMediaProps => ({
  name: textOverlayMedia.fields.name,
  fadeIn: textOverlayMedia.fields.fadeIn,
  responsiveMedia: textOverlayMedia.fields.responsiveMedia,
  overlayColorTop: textOverlayMedia.fields.overlayColorTop,
  overlayColorMiddle: textOverlayMedia.fields.overlayColorMiddle,
  overlayColorBottom: textOverlayMedia.fields.overlayColorBottom,
  overlayOpacity: textOverlayMedia.fields.overlayOpacity,
  videoConfig: textOverlayMedia.fields.videoConfig || null,
  link: textOverlayMedia.fields.link || null,
  locationalRichTextSections:
    textOverlayMedia?.fields.locationalRichTextSections?.map((section) => ({
      ...section.fields,
    })),
});

export function filterOutSectionsWithNoFields(section: SectionType) {
  return section?.fields;
}

export function filterOutSpacerSections(
  section: SectionType,
  hasVerticalSpacing: boolean
) {
  return (
    hasVerticalSpacing || section?.sys.contentType.sys.id !== "spacerSection"
  );
}

export function assetToImageData(asset: Asset): ImageData {
  return {
    src: asset.fields.file.url,
    alt: asset.fields.description || asset.fields.title,
    height: asset.fields.file.details.image?.height,
    width: asset.fields.file.details.image?.width,
  };
}

export function responsiveMediaToAsset(asset: IResponsiveAsset): AssetType {
  return asset.fields.videoAsset &&
    asset.fields.videoAsset.fields.shopifyVideo &&
    isShopifyVideo(asset.fields.videoAsset.fields.shopifyVideo)
    ? {
        sources: asset.fields.videoAsset.fields.shopifyVideo.sources.map(
          ({ url, mimeType }) => ({
            src: url,
            type: mimeType,
          })
        ),
        alt:
          asset.fields.videoAsset.fields.shopifyVideo.previewImage.altText ??
          "",
        type: "video",
        width: asset.fields.videoAsset.fields.shopifyVideo.previewImage.width,
        height: asset.fields.videoAsset.fields.shopifyVideo.previewImage.height,
        posterUrl: asset.fields.videoAsset.fields.shopifyVideo.previewImage.url,
      }
    : {
        src: asset.fields.asset.fields.file.url,
        width: asset.fields.asset.fields.file.details.image?.width || 0,
        height: asset.fields.asset.fields.file.details.image?.height || 0,
        alt: asset.fields.asset.fields.file.fileName,
        type: "image",
      };
}

export default textMediaProps;
