/* eslint-disable import/prefer-default-export */
const defaultTheme = require("tailwindcss/defaultTheme");
const plugin = require("tailwindcss/plugin");

// TODO maybe put these plugins somewhere else (e.g. /plugins/tailwind/*.js)
const customVariantsPlugin = plugin(({ addVariant }) => {
  /**
   * Include 'aria-disabled' along with the built-in 'disabled' modifier, allowing a selector like
   * 'disabled:cursor-default' to also target elements that have `aria-disabled="true"` but not
   * 'disabled'
   */
  addVariant("disabled", ["&[disabled]", "&[aria-disabled='true']"]);

  /**
   * Changes the built-in 'invalid' variant from ':invalid' to '[aria-invalid="true"]'
   */
  addVariant("invalid", "&[aria-invalid='true']");

  /**
   * Adds the 'current' variant for selecting '[aria-current]' and '[data-current]'
   */
  addVariant("current", ["&[aria-current]", "&[data-current]"]);

  /**
   * Adds the 'selected' variant for selecting '[aria-selected]' and '[data-selected]'
   */
  addVariant("selected", ["&[aria-selected]", "&[data-selected]"]);

  /**
   * Add a '.hocus' variant for simultaneously selecting ':hover' and ':focus'
   */
  addVariant("hocus", ["&:hover", "&:focus"]);

  /**
   * Adds an 'expanded' variant for selecting '&[aria-expanded="true"]' and '&[data-expanded]'
   */
  addVariant("expanded", ['&[aria-expanded="true"]', "&[data-expanded]"]);
});

const baseStylesPlugin = plugin(({ addBase, theme }) => {
  addBase({
    html: {
      // Ensure that jump links clear the floating header nav
      scrollPaddingTop: "90px",
    },
    body: {
      color: theme("colors.charcoal"),
      fontSize: theme("fontSize.base"),
      lineHeight: theme("spacing.7"),
    },
    "body, input, textarea, button, select": {
      "-webkit-font-smoothing": "antialiased",
      "-webkit-text-size-adjust": "100%",
      "caret-color": theme("colors.charcoal"),
    },
    "input::placeholder, textarea::placeholder": {
      color: theme("colors.flint"),
    },
  });
});

const formControlsPlugin = plugin(({ addUtilities }) => {
  addUtilities({
    ".appearance-none": {
      // Modify the built-in utiltiy 'appearance-none' to also hide spin button controls (up and
      // down arrows) on inputs with type "number"
      '&[type="number"]': {
        MozAppearance: "textfield",
        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
    },
  });
});

const prosePlugin = plugin(({ addUtilities, theme }) => {
  addUtilities({
    ".prose": {
      "h1, h2, h3, p": {
        marginBottom: theme("spacing.5"),
        marginTop: theme("spacing.5"),
      },
    },
    ".prose-hero": {
      h1: {
        lineHeight: 1,
      },
      [`@media (max-width: ${theme("screens.md")})`]: {
        h1: {
          fontSize: theme("fontSize.xl"),
        },
      },
    },
  });
});

const noScrollbarPlugin = plugin(({ addUtilities }) => {
  addUtilities({
    ".no-scrollbar": {
      "-ms-overflow-style": "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  });
});

const scrollBarSnapPlugin = plugin(({ addUtilities }) => {
  addUtilities({
    ".scroll-bar-snap-x": {
      "overflow-x": "scroll",
      "scroll-snap-type": "x mandatory",
    },
    ".scroll-bar-snap-item": {
      "scroll-snap-align": "start",
    },
  });
});

const webkitClampPlugin = plugin(
  ({ addUtilities, matchUtilities, theme }) => {
    addUtilities({
      ".clamp": {
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      },
      /** Safari has an issue where line clamp doesn't work well
       * for internal block elements, so we make them all inline
       */
      ".clamp *": {
        display: "inline",
      },
      ".clamp-none": {
        overflow: "visible",
        display: "block",
        WebkitBoxOrient: "horizontal",
      },
    });

    matchUtilities(
      {
        clamp: (value) => ({
          WebkitLineClamp: value,
        }),
      },
      { values: theme("lineClamp") }
    );
  },
  {
    theme: {
      lineClamp: {
        1: "1",
        2: "2",
        3: "3",
        5: "5",
      },
    },
  }
);

module.exports = {
  plugins: [
    baseStylesPlugin,
    customVariantsPlugin,
    formControlsPlugin,
    prosePlugin,
    noScrollbarPlugin,
    webkitClampPlugin,
    scrollBarSnapPlugin,
  ],
  content: ["src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    screens: {
      xs: "320px",
      twin: "390px",
      sm: "640px",
      md: "768px",
      twinXl: "744px",
      lg: "1024px",
      full: "1024px",
      xl: "1200px",
      queen: "1280px",
      "2xl": "1400px",
      king: "1440px",
      caliKing: "1920px",
    },
    colors: {
      transparent: "transparent",
      charcoal: "#4d4d4f", // Use white text
      slate: "#787c7f", // Use white text
      flint: {
        DEFAULT: "#abadb0", // Use white text
        10: "#ABADB01A",
        20: "#ABADB033",
        25: "#ABADB040",
      },
      sage: "#bdbdb0", // Use white or charcoal text
      dune: "#f3ecdd", // Use charcoal text
      lightgray: "#e6e6e7",
      white: "#ffffff", // Use charcoal text primarily, slate or flint text secondarily
      black: "#000",
      pine: "#5f7664", // Use white text
      marine: "#466883", // Use white text
      blush: "#ca8d7f", // Use white text
      pearl: "#f6f6f6", // Use charcoal text
    },
    fontFamily: {
      sans: ["Saira", ...defaultTheme.fontFamily.sans], // Paragraph Copy, CTAs, Some Sub-Headers
      serif: ["Yrsa", ...defaultTheme.fontFamily.serif], // Main Headers
    },
    fontSize: {
      "3xl": "60px",
      "2xl": "50px",
      mxl: "46px",
      xl: "40px",
      lg: "32px",
      l: "20px",
      md: "19px",
      input: "16px", // iOS minimum recommended for inputs (or it will zoom in)
      base: "15px",
      sm: "14px",
      xs: "13px",
      "2xs": "12px",
      tiny: "10px",
      h1: "3.875rem",
      h2: "2.5rem",
      h3: "2rem",
      h4: "1.5625rem",
      h5: "1.1875rem",
      h6: ".9375rem",
      body: ".8125rem",
      cta: ".75rem",
    },
    ringColor: (theme) => ({
      ...theme("colors"),
      DEFAULT: theme("colors.pine"),
    }),
    ringOpacity: (theme) => ({
      ...theme("opacity"),
      DEFAULT: 1,
    }),
    extend: {
      letterSpacing: {
        h1: "-0.0775rem",
        h2: "-0.05rem",
        h3: "-0.04rem",
        h4: "-0.0313rem",
        h5: "-0.0119rem",
        h6: "-0.0094rem",
        body: "-0.0081rem",
        cta: ".015rem",
      },
      spacing: {
        0.25: "1px",
        0.5: "2px",
        1.5: "6px",
        2.5: "10px",
        4.5: "18px",
        160: "640px",
      },
      borderRadius: {
        xs: "2px",
        sm: "5px",
      },
      screens: {
        hoverable: { raw: "(hover: hover)" },
        "non-hoverable": { raw: "(hover: none)" },
      },
      height: {
        "hero-mobile": "60vw",
      },
      width: {
        atc: "calc(100% + 4rem)",
      },
      backgroundSize: {
        stretch: "100% 100%",
      },
      gridTemplateRows: {
        10: "repeat(10, minmax(0, 1fr))",
      },
      gridRow: {
        "span-9": "span 9 / span 9",
      },
      lineHeight: {
        small: "27px",
        eighth: "1.125em",
        h1: "3.625rem",
        h2: "2.3125rem",
        h3: "1.875rem",
        h4: "1.4375rem",
        h5: "1.6875rem",
        h6: "1.3125rem",
        body: "1.125rem",
        cta: ".75rem",
      },
      maxWidth: {
        "2xs": "18rem",
        154: "616px",
        160: "640px",
      },
      minHeight: {
        cart: "33vh",
      },
      maxHeight: {
        "5/6": "83%",
        "collapsible-sidebar": "calc(100vh - 120px)",
      },
      zIndex: {
        modal: 100,
        sheet: 20,
        header: 50,
        popover: 100,
        max: 150,
        highest: 2147483647,
      },
      keyframes: {
        "big-bounce": {
          "0%, 100%": {
            transform: "translateY(-66%)",
            animationTimingFunction: "cubic-bezier(0.8, 0, 1, 1)",
          },
          "50%": {
            transform: "translateY(0)",
            animationTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
          },
        },
      },
      animation: {
        "big-bounce": "big-bounce 1s infinite",
      },
      borderWidth: {
        1: "1px",
      },
      textUnderlineOffset: {
        16: "16px",
      },
    },
  },
};
