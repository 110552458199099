import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useBreakpoints } from "src/lib/hooks/useBreakpoints";

const FeaturedCardContext = createContext<{
  cardHeight: number | null;
  setCardHeight: React.Dispatch<React.SetStateAction<number | null>>;
  setRef: (element: HTMLDivElement | null) => void;
}>({
  cardHeight: null,
  setCardHeight:
    // istanbul ignore next
    () => {},
  setRef: () => {},
});

export function FeaturedCardProvider({
  children,
  value,
}: {
  children: React.ReactNode;
  value?: {
    cardHeight: number | null;
    setCardHeight: React.Dispatch<React.SetStateAction<number | null>>;
  };
}) {
  const [cardHeight, setCardHeight] = useState<number | null>(null);

  // Use one observer for all cards, as this is the best for performance
  const resizeObserver = useRef<ResizeObserver | null>(null);
  const { fullVisible } = useBreakpoints();
  const divRef = useRef<HTMLDivElement | null>(null);

  // Set the ref for the card
  const setRef = useCallback((element: HTMLDivElement | null) => {
    if (element && !divRef.current) {
      divRef.current = element;
    }
  }, []);

  useEffect(() => {
    // Only set up observer if desktop and if the divRef is set
    // istanbul ignore if -- react testing library doesn't support ResizeObserver
    if (fullVisible && divRef.current) {
      // We don't care about all entries being observed, just need
      // to check the divRef.current height
      resizeObserver.current = new ResizeObserver(() => {
        if (divRef.current) {
          const { clientHeight } = divRef.current;
          setCardHeight(clientHeight);
        }
      });
      resizeObserver.current.observe(divRef.current);
    }

    return () => {
      resizeObserver.current?.disconnect();
    };
  }, [resizeObserver, fullVisible, divRef]);

  const resolvedValue = useMemo(
    () => ({
      ...(value || { cardHeight, setCardHeight }),
      setRef,
    }),
    [cardHeight, value, setRef]
  );

  return (
    <FeaturedCardContext.Provider value={resolvedValue}>
      {children}
    </FeaturedCardContext.Provider>
  );
}

export const useFeaturedCardHeight = () => {
  const { cardHeight, setRef } = useContext(FeaturedCardContext);
  return {
    featuredCardHeight: cardHeight,
    setCardRef: setRef,
  };
};
